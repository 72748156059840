<template>
    <span class="DotsLoader">
        <span></span>
    </span>
</template>

<script>
export default {
    name: 'DotsLoader',
};
</script>

<style lang="scss">
@keyframes dot-flashing {
    0% {
        transform: translateZ(0) scale(1);
    }

    50%,
    100% {
        transform: translateZ(0) scale(0.6);
        opacity: 0.2;
    }
}

.DotsLoader {
    position: relative;
    display: inline-flex;
    align-items: center;

    >span,
    &::before,
    &::after {
        z-index: 0;
        width: var(--dot-size, 4px);
        height: var(--dot-size, 4px);
        background-color: currentColor;
        border-radius: 50%;
        transform-origin: center center;
        backface-visibility: hidden;
        filter: blur(0);
        animation: dot-flashing 0.75s infinite linear alternate;
        content: "";
    }

    &::before {
        animation-delay: 0s;
    }

    > span {
        animation-delay: 0.3s;
    }

    &::after {
        animation-delay: 0.6s;
    }
}
</style>
