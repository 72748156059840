<template>
    <div class="AiHistoryModalSpinner">
        <SbsSpinner
            size="large"
            colorTheme="blue" />
    </div>
</template>

<script>
export default {
    name: 'AiHistoryModalSpinner',
};
</script>

<style lang="scss" scoped>
.AiHistoryModalSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
