/* eslint-disable no-unused-expressions, no-console */

import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';
import { ClientErrorCodes } from '@/utils/httpStatusCodes.js';
import { ERROR_CHUNK_LOADING_MSGS } from '@/services/errors.js';

const IGNORED_ERROR_STATUSES = [
    ClientErrorCodes.UNAUTHORIZED,
    ClientErrorCodes.FORBIDDEN,
    ClientErrorCodes.AUTHENTICATION_TIMEOUT,
];

const ignoreAnalyticsUrls = [
    'google-analytics.com',
    'mc.yandex.ru',
    'mc.yandex.com',
];

export const setSentryUser = id => {
    Sentry.setUser({ id });
};

export const initSentry = app => {
    if (process.env.VUE_APP_SENTRY_DSN) {
        const isProduction = ['preproduction', 'production'].includes(process.env.VUE_APP_ENV);
        const debug = new URLSearchParams(window.location.search).has('debug');

        Sentry.init({
            app,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            environment: process.env.VUE_APP_ENV,
            enabled: process.env.VUE_APP_SENTRY_ENABLED === 'true',
            trackComponents: true,
            debug,
            integrations: isProduction
                ? [
                    Sentry.replayIntegration({
                        errorSampleRate: 0.1,
                        sessionSampleRate: 0.0,
                    }),
                    captureConsoleIntegration({
                        levels: ['error'],
                    }),
                ]
                : [],
            allowUrls: [
                /https?:\/\/((study|study-ift|study-dev)\.)?sberuniversity\.online\//,
                /https?:\/\/(local\.)?longread\.(8rs|rsui)\.ru(:808[01])?\//,
                /^webpack-internal:/,
            ],
            ignoreErrors: [...ERROR_CHUNK_LOADING_MSGS, /Network Error/i],
            beforeSend: (event, hint) => {
                debug && console.log('[beforeSend error start]', event);

                const error = hint?.originalException;
                const isAxiosError = axios.isAxiosError(error);
                const isIgnoredStatusError = error?.response?.status && IGNORED_ERROR_STATUSES.includes(error?.response.status);
                const isUserAuthError = typeof event?.message === 'string' && event?.message?.includes('[user] error while get data from api'); // error from user store

                if ((isAxiosError && isIgnoredStatusError) || isUserAuthError) {
                    debug && console.log('[beforeSend ignore error]', event);

                    return null;
                }

                return event;
            },
            beforeBreadcrumb(breadcrumb) {
                debug && console.log('[beforeBreadcrumb breadcrumb]', breadcrumb);

                if (
                    breadcrumb.category === 'fetch' && ignoreAnalyticsUrls.some(metricUrl => breadcrumb.data?.url?.includes?.(metricUrl))
                ) {
                    debug && console.log('[beforeBreadcrumb ignore breadcrumb]', breadcrumb);

                    return null;
                }

                return breadcrumb;
            },
        });
    }
};
