import { AiMessagesPromptTypes } from '@/constants/AI.js';

export const AiContextType = {
    MODULE: 'module',
    SECTION: 'section',
    PAGE: 'page',
};

export const THEME_TITLE_MAX_LENGTH = 5000;
export const THEME_DESCRIPTION_MAX_LENGTH = 5000;

export const HISTORY_ITEM_DATA_MAP = {
    [AiMessagesPromptTypes.IMPROVE_WRITING]: {
        text: 'Улучши текст в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icons8-magic-wand.svg'),
    },
    [AiMessagesPromptTypes.CORRECT_WRITING]: {
        text: 'Исправь правописание выделенного в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icons8-clipboard-check.svg'),
    },
    [AiMessagesPromptTypes.MAKE_SHORTER]: {
        text: 'Сделай короче текст в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icons8-short-text.svg'),
    },
    [AiMessagesPromptTypes.MAKE_LONGER]: {
        text: 'Сделай длиннее текст в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icons8-word-wrap.svg'),
    },
    [AiMessagesPromptTypes.SUMMARIZE]: {
        text: 'Подвести итог из выделенного в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icons8-quote-term.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_INCORRECT_ANSWERS]: {
        text: 'Создать неправильные ответы для вопроса в тесте проекта',
        icon: require('@editor-settings/assets/icons/icons8-delete-talk-alt.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_IMAGE]: {
        text: 'Создай картинку для проекта',
        icon: require('@/assets/svg/icons8-image.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_TASK]: {
        text: 'Создай задание для проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/icon-edit-property.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_CONSOLIDATE_MATERIAL_TASK]: {
        text: 'Создай задание для проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/icon-edit-property.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_TESTING_SKILLS_TASK]: {
        text: 'Создай задание для проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/icon-edit-property.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_REFLECTION_ON_MATERIAL_TASK]: {
        text: 'Создай задание для проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/icon-edit-property.svg'),
    },
    [AiMessagesPromptTypes.MAKE_QUESTION]: {
        text: 'Ответь на вопрос в проекте',
        icon: require('@/components/WysiwygRedactor/assets/icons/icon-question-bubble.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_TEST]: {
        text: 'Создай тест для проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/ic_report-card.svg'),
    },
    [AiMessagesPromptTypes.GENERATE_TEST_QUESTION]: {
        text: 'Создай вопрос в тесте проекта',
        icon: require('@/components/WysiwygRedactor/assets/icons/ic_tick-box.svg'),
    },
};
