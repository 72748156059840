<template>
    <div class="AiHistoryModalEmtpyStub">
        <div class="AiHistoryModalEmtpyStub__IconWrapper">
            <InlineSvg
                class="AiHistoryModalEmtpyStub__Icon"
                :src="require('@/assets/svg/icon-history-sessions-stub.svg')" />
        </div>
        <div class="AiHistoryModalEmtpyStub__Description">
            Здесь будет отображаться<br>
            история генераций
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiHistoryModalEmtpyStub',
    components: {
        InlineSvg,
    },
};
</script>

<style lang="scss" scoped>
.AiHistoryModalEmtpyStub {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.AiHistoryModalEmtpyStub__IconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: $gray-ultra-light;
    border-radius: 50%;
}

.AiHistoryModalEmtpyStub__Description {
    color: $gray-deep;
    text-align: center;

    @include typo-additional-demi;
}
</style>
