<template>
    <SbsTooltip
        :content="disabledTooltip"
        tooltipContentClass="AiQueryTextarea__Tooltip"
        fullWidth
        maxWidth="209"
        position="top"
        :disabled="disabledTextareaTooltip">
        <SbsTextarea
            v-model="query"
            v-bind="textareaData"
            @focus="setFocus(true)"
            @blur="setFocus(false)">
            <template #append>
                <SbsTooltip
                    class="AiQueryTextarea__IconWrapper"
                    content="Остановить генерацию"
                    :disabled="!isPaused || disabled">
                    <SbsButtonIcon
                        class="AiQueryTextarea__Btn"
                        width="24"
                        height="24"
                        :disabled="isDisabledConfirmBtn"
                        @mouseenter="setPauseBtnHovered(true)"
                        @mouseleave="setPauseBtnHovered(false)"
                        @click="handleClick">
                        <InlineSvg
                            :key="textareaActionBtn"
                            class="AiQueryTextarea__BtnIcon"
                            :src="textareaActionBtn" />
                    </SbsButtonIcon>
                </SbsTooltip>
            </template>
            <template
                v-if="isShowCounter && isFocused"
                #counter>
                <div class="AiQueryTextarea__Counter">
                    ({{ query.length }} / {{ maxLength }})
                </div>
            </template>
        </SbsTextarea>
    </SbsTooltip>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import pauseIcon from '@/assets/svg/icons8-pause-button-large.svg';
import sendIcon from '@/assets/svg/icons8-sent1.svg';

export default {
    name: 'AiQueryTextarea',
    components: {
        InlineSvg,
    },
    emits: ['update:modelValue', 'confirm', 'cancel'],
    data: () => ({
        isFocused: false,
        isPauseBtnHovered: false,
    }),
    computed: {
        query: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        isError() {
            if (!this.maxLength) return false;

            return this.isFillingError;
        },
        isDisabledConfirmBtn() {
            if (this.isPaused) return false;

            return this.isError || (!this.withPrompt && !this.query.length) || this.disabled;
        },
        isShowCounter() {
            return Boolean(this.maxLength);
        },
        textareaActionBtn() {
            return this.isPaused ? pauseIcon : sendIcon;
        },
        disabledTextarea() {
            return this.isPaused || this.disabled;
        },
        disabledTextareaTooltip() {
            return this.isPauseBtnHovered || !this.disabledTextarea;
        },
        textareaData() {
            return {
                class: 'AiQueryTextarea',
                textareaFieldClass: 'AiQueryTextarea__Field',
                placeholder: this.placeholder,
                rows: 1,
                maxHeight: 124,
                colorTheme: 'primary',
                fullWidth: true,
                autoGrow: true,
                disabled: this.disabledTextarea,
            };
        },
        disabledTooltip() {
            if (this.isPaused) {
                return 'Дождитесь пока нейросеть закончит писать текст';
            }

            return 'Данный тип чата не поддерживает отправку сообщения';
        },
    },
    methods: {
        handleClick() {
            const emitName = this.isPaused ? 'cancel' : 'confirm';

            this.$emit(emitName);
        },
        setFocus(isFocused) {
            this.isFocused = isFocused;
        },
        setPauseBtnHovered(isHovered) {
            this.isPauseBtnHovered = isHovered;
        },
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        isPaused: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isFillingError: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: null,
        },
        withPrompt: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Спросите ИИ, что хотите',
        },
    },
};
</script>

<style lang="scss">
.AiQueryTextarea {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
}

.AiQueryTextarea__Field {
    padding: 14px 20px;
    padding-right: 54px !important;
}

.AiQueryTextarea__IconWrapper {
    position: absolute;
    right: 20px;
    bottom: 14px;
    cursor: pointer;
}

.AiQueryTextarea__Btn {
    &:hover {
        path {
            fill: $primary;
        }
    }
}

.AiQueryTextarea__BtnIcon {
    width: 24px;
    height: 24px;
    transition: $trs;

    .AiQueryTextarea.disabled & {
        filter: grayscale(1);
    }
}

.AiQueryTextarea__Counter {
    position: absolute;
    right: 15px;
    bottom: 4px;
    width: 95px;
    color: $gray-deep;

    @include typo-subsecondary-demi;
}

.AiQueryTextarea__Tooltip {
    text-align: left;
}
</style>
