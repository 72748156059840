import axios from 'axios';
import { BASE_URI } from '@/api/CONST.js';
import { AiSessionTypes } from '@/constants/AI.js';
import { snakeCaseObject } from '@/utils/helpers.js';

const prefix = `${BASE_URI}/ai`;

/**
 * create AI session
 * @param {object} data
 * @param {string|number} data.activityId
 * @param {string} data.promptType
 * @param {string} [data.type]
 * @param {object} [data.params]
 *
 * @returns {import('axios').AxiosResponse<{
 *   success: boolean,
 *   data: {
 *     id: number
 *   }
 * }>}
 */
export const createAiSession = data => {
    const {
        activityId,
        promptType,
        params = {},
        type = AiSessionTypes.TEXT_BLOCK,
    } = data;

    const snakedParams = snakeCaseObject(params);

    const sessionParams = {
        ...snakedParams,
        activity_id: activityId,
    };

    return axios.post(`${prefix}/sessions`, {
        type,
        prompt_type: promptType,
        params: sessionParams,
    });
};

/**
 * get AI session
 * @param {string|number} sessionId session id
 * @returns Promise
 */
export const getAiSession = sessionId => axios.get(`${prefix}/sessions/${sessionId}`);

/**
 * send message to ai session
 * @param {string|number} sessionId session id
 * @param {object} data search query data
 * @param {string} data.text search query text
 * @returns Promise
 */
export const sendAiMessage = (sessionId, data) => axios.post(`${prefix}/sessions/${sessionId}/messages`, data);

/**
 * gets messages for ai session
 * @param {string|number} sessionId session id
 * @param {object} [params]
 * @return {Promise<import('axios').AxiosResponse>}
 */
export const getAiMessages = (sessionId, params) => axios.get(`${prefix}/sessions/${sessionId}/messages`, { params });

/**
 * get AI support problems list
 * @returns Promise
 */
export const getAiSupportProblems = () => axios.get(`${prefix}/dictionaries/problems`);

/**
 * get AI support dialogs themes list
 * @returns Promise
 */
export const getAiSupportDialogThemes = () => axios.get(`${prefix}/dictionaries/dialog-themes`);

/**
 * set like on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const setLikeAiMessage = ({ sessionId, messageId }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/like`);

/**
 * set dislike on AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @param {object} data.data dislike request data
 * @returns Promise
 */
export const setDislikeAiMessage = ({ sessionId, messageId, data }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/dislike`, data);

/**
 * cancel ai message sending
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise<import('axios').AxiosResponse>
 */
export const cancelMessage = ({ sessionId, messageId }) => axios.delete(`${prefix}/sessions/${sessionId}/messages/${messageId}`);

/**
 * @param {object} opions
 * @param {string|number} opions.blockId
 * @returns {Promise<import('axios').AxiosResponse<{
 *     data: {
 *     context_type: 'page' | 'section' | 'module',
 *     is_available: boolean,
 *   }
 * }>>}
 */
export const getBlockContextTypes = ({ blockId }) => axios.get(`${prefix}/blocks/${blockId}/context-types`);

/**
 * get AI sessions list
 * @param {object} params
 * @param {string|number} params.developmentCenterId development center id
 * @param {number} params.daysPeriod days period
 * @param {number} params.page page
 * @returns Promise
 */
export const getAiHistorySessions = ({ developmentCenterId, daysPeriod, page }) => axios.get(`${prefix}/sessions`, {
    params: {
        development_center_id: developmentCenterId,
        days_period: daysPeriod,
        page,
    },
});


/**
 * set viewed AI message
 * @param {object} data session and message data
 * @param {string|number} data.messageId session id
 * @param {string|number} data.sessionId message id
 * @returns Promise
 */
export const setViewedAiMessage = ({ sessionId, messageId }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/view`);

/**
 * @param {{sessionId, messageId }}params
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export const retryAiMessage = ({ sessionId, messageId }) => axios.post(`${prefix}/sessions/${sessionId}/messages/${messageId}/regenerate`);
