<template>
    <div class="AiHistoryModalHeader">
        <InlineSvg :src="require('@/assets/svg/icon-ai-start.svg')" />
        <span>ИИ–История генераций</span>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiHistoryModalHeader',
    components: { InlineSvg },
};
</script>

<style lang="scss" scoped>
.AiHistoryModalHeader {
    display: flex;
    gap: 10px;
    align-items: center;

    @include typo-primary-medium;
}
</style>
