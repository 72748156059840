<template>
    <div class="AiModalActionBtns">
        <template
            v-for="(item, ind) in actionsList"
            :key="ind">
            <SbsTooltip
                v-if="item.type === 'button'"
                tooltipClass="AiModalActionBtns__TooltipContent"
                position="bottom"
                maxWidth="250"
                :content="item.tooltip">
                <SbsButton
                    class="AiModalActionBtns__Btn"
                    :class="{ 'AiModalActionBtns__Btn--Active': item.active }"
                    v-bind="buttonData"
                    @click="item.handler">
                    <InlineSvg
                        class="AiModalActionBtns__Icon"
                        :src="item.icon" />
                </SbsButton>
            </SbsTooltip>
            <div
                v-if="item.type === 'divider'"
                class="AiModalActionBtns__RateDivider">
                •
            </div>
        </template>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

const buttonData = {
    static: true,
    bordered: false,
    size: 's-small',
    hoverBackground: 'gray-ultra-light',
};

export default {
    name: 'AiModalActionBtns',
    components: {
        InlineSvg,
    },
    emits: ['like', 'dislike', 'copy', 'try:again'],
    data() {
        return {
            buttonData,
        };
    },
    computed: {
        isGoodAnswer() {
            return this.grade === 'like';
        },
        isBadAnswer() {
            return this.grade === 'dislike';
        },
        actionsList() {
            return [
                ...(this.actions.reactions
                    ? [
                        {
                            type: 'button',
                            tooltip: 'Хороший ответ',
                            icon: require('@/assets/svg/ic_filled-like.svg'),
                            active: this.isGoodAnswer,
                            handler: () => this.$emit('like'),
                        },
                        {
                            type: 'button',
                            tooltip: 'Плохой ответ',
                            icon: require('@/assets/svg/ic_filled-dislike.svg'),
                            active: this.isBadAnswer,
                            handler: () => this.$emit('dislike'),
                        },
                    ]
                    : []),
                ...(this.actions.reactions && (this.actions.copy || this.actions.refresh)
                    ? [{ type: 'divider' }]
                    : []),
                ...(this.actions.copy
                    ? [
                        {
                            type: 'button',
                            tooltip: 'Копировать',
                            icon: require('@/assets/svg/ic_copy_filled.svg'),
                            active: false,
                            handler: () => this.$emit('copy'),
                        },
                    ]
                    : []),
                ...(this.actions.refresh
                    ? [
                        {
                            type: 'button',
                            tooltip: 'Попробовать заново',
                            icon: require('@/assets/svg/ic_refresh_filled.svg'),
                            active: false,
                            handler: () => this.$emit('try:again'),
                        },
                    ]
                    : []),
            ];
        },
    },
    props: {
        /**
         * @type {import('vue').PropType<'like' | 'dislike'>}
         */
        grade: {
            type: String,
            default: null,
        },
        /**
         * @type {import('vue').PropType<Record<'reactions'|'refresh'|'copy', boolean|undefined>>}
         */
        actions: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.AiModalActionBtns {
    display: flex;
    align-items: center;
}

.AiModalActionBtns__TooltipContent {
    text-align: left;
}

.AiModalActionBtns__Btn {
    padding: 7px;

    @include typo-additional-book;

    color: #74777d !important;

    &--Active {
        color: #000000 !important;
    }
}

.AiModalActionBtns__Icon {
    width: 16px;
    height: 16px;
}

.AiModalActionBtns__RateDivider {
    margin: 0 5px;
    color: $gray-light;
}
</style>
