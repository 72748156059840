<template>
    <component
        :is="component"
        v-if="component"
        :question="question" />
</template>

<script>
import { AiQuestionType } from '@/admin/components/AI/AiModal/contants.js';
import AiQuestionSingle from './AiQuestionSingle.vue';
import AiQuestionMulti from './AiQuestionMulti.vue';

export default {
    name: 'AiQuestionResolver',
    computed: {
        component() {
            if (this.question.type === AiQuestionType.OneChoice) return AiQuestionSingle;

            if (this.question.type === AiQuestionType.MultipleChoice) return AiQuestionMulti;

            return null;
        },
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
};
</script>
