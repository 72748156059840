/**
 * @readonly
 * @enum {string}
 */
export const AiMessagesPromptTypes = {
    IMPROVE_WRITING: 'improve_writing',
    CORRECT_WRITING: 'correct_writing',
    MAKE_SHORTER: 'make_shorter',
    MAKE_LONGER: 'make_longer',
    SUMMARIZE: 'summarize',
    GENERATE_INCORRECT_ANSWERS: 'generate_incorrect_answers',
    GENERATE_IMAGE: 'generate_image',
    GENERATE_TASK: 'generate_task',
    MAKE_QUESTION: 'make_question',
    GENERATE_CONSOLIDATE_MATERIAL_TASK: 'generate_consolidate_material_task',
    GENERATE_TESTING_SKILLS_TASK: 'generate_testing_skills_task',
    GENERATE_REFLECTION_ON_MATERIAL_TASK: 'generate_reflection_on_material_task',
    GENERATE_TEST: 'generate_test',
    GENERATE_TEST_QUESTION: 'generate_test_question',
};

/**
 * @readonly
 * @enum {string}
 */
export const AiSessionTypes = {
    TEXT_BLOCK: 'text_block',
    TEST_OR_QUIZ_QUESTION: 'test_or_quiz_question',
};

export const AiQueryFieldTypes = {
    QUESTION: 'query_field_question',
    IMAGE: 'query_field_image',
    TASK: 'query_field_task',
};

export const AiMessageStatus = {
    CREATED: 'created',
    PROCESSING: 'processing',
    PROCESSED: 'processed',
    CANCELED: 'canceled',
    FAILED: 'failed',
};

export const MAX_ENTITY_NAME_LENGTH = 50;

export const MAX_POPUP_ENTITY_NAME_LENGTH = 9;
