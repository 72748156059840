import { AiMessagesPromptTypes, AiMessageStatus, MAX_POPUP_ENTITY_NAME_LENGTH } from '@/constants/AI.js';
import { AiMessageAuthorType } from '@/admin/components/AI/AiModal/contants.js';
import { getRenderMarkdownResult } from '@/utils/helpers.js';
import { HISTORY_ITEM_DATA_MAP } from '@/admin/components/AI/constants.js';

/**
 * @param {Array} questions
 * @returns {ClipboardItem}
 */
export const getCopyItemsForQuestions = questions => {
    const textPlain = questions.map(question => {
        const copyQuestionTextData = [
            question.title,
            question.description,
            question.answers.map((answer, ind) => `${Number(ind) + 1}. ${answer.title}`).join('\n'),
        ].join('\n');

        return copyQuestionTextData;
    }).join('\n\n');

    const textHtml = questions.flatMap(question => [
        `<h4>${question.title}</h4>`,
        `<p>${question.description}</p>`,
        `<ol>${question.answers.map((answer, ind) => `<li><p>${Number(ind) + 1}. ${answer.title}</p></li>`).join('')}</ol>`,
    ]).join('');

    const textBlob = new Blob([textPlain], { type: 'text/plain' });
    const htmlBlob = new Blob([textHtml], { type: 'text/html' });
    const copyRecords = {
        [textBlob.type]: textBlob,
        [htmlBlob.type]: htmlBlob,
    };
    const copyItems = new ClipboardItem(copyRecords);

    return copyItems;
};

export const getSlicedMessageEntityName = (name = '') => {
    if (name.length > MAX_POPUP_ENTITY_NAME_LENGTH) {
        return `${name.slice(0, MAX_POPUP_ENTITY_NAME_LENGTH)}...`;
    }

    return name;
};

export const getAiHistoryMessageText = session => {
    const projectName = getSlicedMessageEntityName(session.location?.solution?.name ?? 'Проект');

    return `${HISTORY_ITEM_DATA_MAP[session.last_message.prompt_type].text} ${projectName}`;
};

export const getAiMessageText = message => {
    if (message.text) return message.text;

    const isAssistant = message.author === AiMessageAuthorType.ASSISTANT;
    const isUser = message.author === AiMessageAuthorType.USER;

    if (isAssistant && [AiMessagesPromptTypes.GENERATE_TEST, AiMessagesPromptTypes.GENERATE_TEST_QUESTION].includes(message.prompt_type)) {
        return 'Вот вопросы с предложенными вариантами ответов, которые сгенерированы на основе вашего запроса';
    }

    if (isAssistant && message.prompt_type === AiMessagesPromptTypes.GENERATE_INCORRECT_ANSWERS) {
        return 'Вот несколько объемных, но совершенно неверных определений для машинного обучения. Эти ответы явно далеки от реальности, но выглядят достаточно научно, чтобы ввести кого-то в заблуждение.';
    }

    if (isUser && message.prompt_type === AiMessagesPromptTypes.GENERATE_INCORRECT_ANSWERS) {
        return 'Создай неправильные ответы для вопроса в тесте проекта';
    }

    return '';
};

export const getResultMessage = message => {
    const text = getAiMessageText(message);

    // TODO возможно рендерить текст нужно только для определенных типов результатов
    // if (this.message.prompt_type ?????)
    const renderedText = getRenderMarkdownResult(text);

    return renderedText;
};

export const isMessageProcessing = message => [AiMessageStatus.PROCESSING, AiMessageStatus.CREATED].includes(message?.status);
