import { useStore } from 'vuex';
import { computed } from 'vue';
import { ACTIVITY_BLOCK_TYPES } from '@/constants/index.js';
import { TestQuizTypes } from '@/components/Blocks/BlockTestOrQuiz/constants.js';

export const useGlobalPaste = () => {
    const store = useStore();
    const copiedQuestion = computed(() => store.state.AI.copiedQuestions);
    const copiedIncorrectAnswers = computed(() => store.state.AI.copiedIncorrectAnswers);
    const activeBlock = computed(() => store.getters['longread/activeBlock']);

    /**
     * @param {ClipboardEvent} event
     */
    const handlePastePage = event => {
        const isHaveCopiedQuestions = copiedQuestion.value?.length > 0;
        const isHaveCopiedIncorrectAnswer = copiedIncorrectAnswers.value?.length > 0;
        const isInsideBlockTestOrQuiz = Boolean(activeBlock.value) &&
            activeBlock.value.type === ACTIVITY_BLOCK_TYPES.TestOrQuiz &&
            activeBlock.value.data.content.type === TestQuizTypes.TEST;

        const preventDefault = () => {
            event.preventDefault();
            event.stopPropagation();
        };

        if (isHaveCopiedQuestions || isHaveCopiedIncorrectAnswer) {
            if (isInsideBlockTestOrQuiz) {
                if (isHaveCopiedQuestions) {
                    preventDefault();
                    store.dispatch('longread/addAiCopiedQuestions', {
                        blockId: activeBlock.value.id,
                        questions: copiedQuestion.value,
                    });
                } else if (isHaveCopiedIncorrectAnswer) {
                    store.dispatch('longread/addAiCopiedIncorrectAnswers', {
                        blockId: activeBlock.value.id,
                        incorrectAnswers: copiedIncorrectAnswers.value,
                        preventDefault,
                    });
                }
            }

            store.dispatch('AI/clearCopyQuestions');
            store.dispatch('AI/clearCopyIncorrectAnswers');
            navigator.clipboard.writeText('');
        }
    };

    document.addEventListener('paste', handlePastePage, { capture: true });
};
