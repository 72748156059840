<template>
    <SbsTooltip
        :content="infoText"
        :width="infoWidth"
        :position="tooltipPosition"
        :disabled="tooltipDisabled"
        tooltipClass="text-left typo-subpsecondary-book">
        <div
            class="info-icon rounded-circle d-flex justify-content-center align-items-center cursor-pointer"
            :class="computedClass">
            <svg
                width="6"
                height="12"
                viewBox="0 0 6 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.97845 11.3922C4.47414 11.5894 4.07328 11.7414 3.77263 11.8448C3.47198 11.9483 3.12608 12 2.72845 12C2.12069 12 1.64547 11.8513 1.30603 11.5539C0.969828 11.2597 0.801724 10.8815 0.801724 10.4224C0.801724 10.2446 0.814655 10.0636 0.840517 9.87931C0.863146 9.69181 0.905172 9.48491 0.960129 9.24892L1.59052 7.02478C1.64547 6.81142 1.69397 6.61099 1.73276 6.42026C1.77155 6.22953 1.78771 6.05496 1.78771 5.89655C1.78771 5.6153 1.72953 5.41487 1.61315 5.30496C1.49353 5.19181 1.27047 5.13685 0.940732 5.13685C0.779095 5.13685 0.610991 5.16271 0.439655 5.21121C0.268318 5.26293 0.122844 5.31142 0 5.35668L0.168103 4.67134C0.578663 4.50323 0.97306 4.36099 1.35129 4.24138C1.72629 4.12177 2.08513 4.06034 2.4181 4.06034C3.02263 4.06034 3.49138 4.20905 3.81789 4.5C4.1444 4.79095 4.30927 5.17241 4.30927 5.63793C4.30927 5.73491 4.29957 5.90625 4.27694 6.14871C4.25431 6.39116 4.21228 6.61422 4.15086 6.82112L3.52371 9.03879C3.47198 9.2166 3.42672 9.42026 3.3847 9.64655C3.3459 9.87284 3.32651 10.0474 3.32651 10.1638C3.32651 10.458 3.39116 10.6616 3.52371 10.7683C3.65302 10.875 3.88254 10.9267 4.20905 10.9267C4.36099 10.9267 4.53233 10.9009 4.72629 10.8491C4.91703 10.7942 5.05603 10.7489 5.14332 10.7069L4.97845 11.3922ZM4.8653 2.38901C4.57435 2.66056 4.22198 2.79634 3.81142 2.79634C3.40086 2.79634 3.04526 2.66056 2.75108 2.38901C2.4569 2.11746 2.31142 1.78772 2.31142 1.40302C2.31142 1.01832 2.46013 0.685345 2.75108 0.41056C3.04526 0.135775 3.40086 0 3.81142 0C4.22198 0 4.57435 0.135775 4.8653 0.41056C5.15948 0.685345 5.30496 1.01832 5.30496 1.40302C5.30496 1.78772 5.15948 2.11746 4.8653 2.38901Z"
                    :fill="fillColor" />
            </svg>
        </div>
    </SbsTooltip>
</template>

<script>
import { AllColors } from '@sbs/uikit-constructor/src/lib/utils/colors.js';
import { AllPositions } from '@sbs/uikit-constructor/src/lib/utils/positions.js';

export default {
    name: 'InfoIcon',
    computed: {
        computedClass() {
            return `bg-${this.backgroundTheme} bg-hover-${this.hoverBackground}`;
        },
        fillColor() {
            return this.iconColor === AllColors.BLACK ? '#000000' : '#FFFFFF';
        },
    },
    props: {
        backgroundTheme: {
            type: String,
            default: AllColors.GRAY_ULTRA_LIGHT,
            validator: value => Object.values(AllColors).includes(value),
        },
        hoverBackground: {
            type: String,
            default: AllColors.LIGHTYELLOW,
            validator: value => Object.values(AllColors).includes(value),
        },
        infoText: {
            type: String,
            default: '',
        },
        infoWidth: {
            type: [String, Number],
            default: null,
        },
        tooltipPosition: {
            type: String,
            default: AllPositions.BOTTOM,
            validator: value => Object.values(AllPositions).includes(value),
        },
        tooltipDisabled: {
            type: Boolean,
            default: false,
        },
        iconColor: {
            type: String,
            default: AllColors.BLACK,
            validator: value => [AllColors.BLACK, AllColors.WHITE].includes(value),
        },
    },
};
</script>

<style>
.info-icon {
    width: 26px;
    height: 26px;
}
</style>
