<template>
    <div class="AiHistoryModalInfo">
        <div class="AiHistoryModalInfo__Block">
            Здесь отображается история генераций за 30 дней, которые вы запускали в текущем центре разработке. Вы можете просматривать и повторно использовать их:
        </div>
    </div>
</template>

<script>
export default {
    name: 'AiHistoryModalInfo',
};
</script>

<style lang="scss" scoped>
.AiHistoryModalInfo__Block {
    position: relative;
    margin-left: 15px;
    padding: 20px 25px;
    background: $gray-ultra-light;
    border-radius: 20px;

    @include typo-subprimary-demi;

    &::before {
        position: absolute;
        top: 0;
        left: -7px;
        width: 20px;
        height: 20px;
        background: $gray-ultra-light;
        border-radius: 50%;
        transform: rotate(320deg);
        content: "";
        clip-path: inset(0 0 50% 50%);
    }
}
</style>
