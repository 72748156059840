<template>
    <div
        v-if="links.length"
        class="AiQueryLinks">
        <div class="AiQueryLinks__Title">
            Список ссылок из генерации:
        </div>
        <SbsSmoothReflow class="AiQueryLinks__Items">
            <SbsLink
                v-for="{ id, link } in displayedLinks"
                :key="id"
                class="AiQueryLinks__Item"
                colorTheme="black"
                underlineTheme="gray"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                :href="link">
                {{ link }}
                <template #prepend>
                    <img
                        class="AiQueryLinks__ItemImage"
                        :src="getFaviconIcon(link)">
                </template>
            </SbsLink>
        </SbsSmoothReflow>
        <div
            v-if="isShowCollapseBtn"
            class="AiQueryLinks__Btn"
            static
            :bordered="false"
            @click="isShowAll = !isShowAll">
            <div>{{ collapseBtnTitle }}</div>
            <InlineSvg
                class="AiQueryLinks__BtnChevron"
                :class="{ 'AiQueryLinks__BtnChevron--Rotate': isShowAll }"
                :src="require('@/assets/svg/icon-arrow-down.svg')" />
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

const MAX_DISPLAYED_LINKS = 5;

export default {
    name: 'AiQueryLinks',
    components: {
        InlineSvg,
    },
    data() {
        return {
            isShowAll: false,
        };
    },
    computed: {
        isShowCollapseBtn() {
            return this.links.length > MAX_DISPLAYED_LINKS;
        },
        collapseBtnTitle() {
            return this.isShowAll ? 'Свернуть' : 'Развернуть';
        },
        displayedLinks() {
            if (this.isShowAll) return this.links;

            return this.links.slice(0, MAX_DISPLAYED_LINKS);
        },
    },
    methods: {
        // todo: убрать, как появится бек, иконка должна будет браться оттуда, как в активити с ссылками
        getFaviconIcon(link) {
            return `https://s2.googleusercontent.com/s2/favicons?domain=${link}`;
        },
    },
    props: {
        links: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>

<style lang="scss" scoped>
.AiQueryLinks {
    background: $gray-ultra-light;
    border-radius: 16px;
    padding: 15px 20px;
}

.AiQueryLinks__Title {
    margin-bottom: 5px;
    @include typo-secondary-medium;
}

.AiQueryLinks__Items {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.AiQueryLinks__Item {
    display: flex;
    align-items: center;
    gap: 5px;
    @include typo-secondary-book;
}

.AiQueryLinks__ItemImage {
    width: 12px;
    height: 12px;
}

.AiQueryLinks__Btn {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $gray-deep;
    cursor: pointer;
    @include typo-secondary-book;

    path {
        fill: $gray-deep;
    }
}

.AiQueryLinks__BtnChevron {
    transition: transform 0.3s ease;
}

.AiQueryLinks__BtnChevron--Rotate {
    transform: rotate(180deg);
}
</style>
