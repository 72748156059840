<template>
    <SbsModal
        v-model="isShowModal"
        class="AiHistoryModal"
        modalBodyClass="AiHistoryModal__ModalBody"
        modalContentClass="AiHistoryModal__ModalContent"
        modalContainerClass="AiHistoryModal__ModalContainer"
        width="450"
        height="100%"
        position="right"
        :showOverlay="false"
        :bodyScrollLock="false">
        <AiHistoryModalHeader class="AiHistoryModal__Header" />
        <AiHistoryModalInfo class="AiHistoryModal__Info" />
        <AiHistoryModalSpinner
            v-if="!historyByDateItems.length && loading"
            class="AiHistoryModal__Spinner" />
        <div
            v-else-if="historyByDateItems.length"
            class="AiHistoryModal__DateItems">
            <template
                v-for="history in historyByDateItems"
                :key="history.dateTitle">
                <div
                    v-if="history.items.length"
                    class="AiHistoryModal__DateItem">
                    <div class="AiHistoryModal__DateItemTitle">
                        {{ history.dateTitle }}
                    </div>
                    <div class="AiHistoryModal__Items">
                        <AiHistoryModalItem
                            v-for="item in history.items"
                            :key="item.id"
                            :item="item"
                            @click="handleClickHistoryItem(item)" />
                    </div>
                </div>
            </template>
        </div>
        <AiHistoryModalEmtpyStub
            v-else
            class="AiHistoryModal__EmptyStub" />
        <ScrollPreloader
            v-if="!isLastPage"
            class="AiHistoryModal__Loader"
            :loading="loading"
            @load="handleScrollLoader" />
    </SbsModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { parseISO, startOfToday, startOfYesterday, subDays, isAfter } from 'date-fns';
import ScrollPreloader from '@/components/UI/ScrollPreloader.vue';
import AiHistoryModalHeader from './components/AiHistoryModalHeader.vue';
import AiHistoryModalInfo from './components/AiHistoryModalInfo.vue';
import AiHistoryModalItem from './components/AiHistoryModalItem.vue';
import AiHistoryModalEmtpyStub from './components/AiHistoryModalEmtpyStub.vue';
import AiHistoryModalSpinner from './components/AiHistoryModalSpinner.vue';

const SEVEN_DAYS = 7;
const THIRTY_DAYS = 30;

export default {
    name: 'AiHistoryModal',
    components: {
        AiHistoryModalHeader,
        AiHistoryModalInfo,
        AiHistoryModalItem,
        AiHistoryModalEmtpyStub,
        AiHistoryModalSpinner,
        ScrollPreloader,
    },
    emits: ['update:modelValue'],
    computed: {
        ...mapGetters('AI', {
            historySessionsList: 'historySessionsList',
            loading: 'historySessionsLoading',
            page: 'currentHistorySessionsPage',
            lastPage: 'historySessionsLastPage',
        }),
        isShowModal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        isLastPage() {
            return this.page >= this.lastPage;
        },
        historyByDateItems() {
            if (!this.historySessionsList.length) return [];

            return this.groupByDate(this.historySessionsList);
        },
    },
    methods: {
        ...mapActions('AI', [
            'showModalAiBySessionId',
            'getAiHistorySessions',
            'resetHistorySessionsData',
        ]),
        groupByDate(items) {
            const today = startOfToday();
            const yesterday = startOfYesterday();
            const sevenDaysAgo = subDays(today, SEVEN_DAYS);
            const thirtyDaysAgo = subDays(today, THIRTY_DAYS);

            const todayItems = items.filter(item => isAfter(parseISO(item.last_message.updated_at), today));
            const yesterdayItems = items.filter(item => isAfter(parseISO(item.last_message.updated_at), yesterday) &&
                !isAfter(parseISO(item.last_message.updated_at), today));
            const last7DayItems = items.filter(item => isAfter(parseISO(item.last_message.updated_at), sevenDaysAgo) &&
                !isAfter(parseISO(item.last_message.updated_at), yesterday));
            const last30DayItems = items.filter(item => isAfter(parseISO(item.last_message.updated_at), thirtyDaysAgo) &&
                !isAfter(parseISO(item.last_message.updated_at), sevenDaysAgo));

            const groups = [];

            if (todayItems.length > 0) {
                groups.push({
                    dateTitle: 'Сегодня',
                    items: todayItems,
                });
            }

            if (yesterdayItems.length > 0) {
                groups.push({
                    dateTitle: 'Вчера',
                    items: yesterdayItems,
                });
            }

            if (last7DayItems.length > 0) {
                groups.push({
                    dateTitle: 'Последние 7 дней',
                    items: last7DayItems,
                });
            }

            if (last30DayItems.length > 0) {
                groups.push({
                    dateTitle: 'Последние 30 дней',
                    items: last30DayItems,
                });
            }

            return groups;
        },
        handleClickHistoryItem(item) {
            this.isShowModal = false;
            this.showModalAiBySessionId({
                sessionId: item.id,
            });
        },
        getHistorySessions({ page, developmentCenterId }) {
            this.getAiHistorySessions({
                developmentCenterId: developmentCenterId || this.$route.params.developmentCenterId,
                page,
            });
        },
        handleScrollLoader() {
            this.getHistorySessions({ page: this.page + 1 });
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        '$route.params.developmentCenterId'(developmentCenterId, prevDevelopmentCenterId) {
            if (!this.isShowModal) return;

            if (developmentCenterId && developmentCenterId !== prevDevelopmentCenterId) {
                this.resetHistorySessionsData();
            }

            this.getAiHistorySessions({
                developmentCenterId,
                page: 1,
            });
        },
        isShowModal(value) {
            if (!value) return;

            this.getHistorySessions({ page: 1 });
        },
    },
};
</script>

<style lang="scss">
.AiHistoryModal__ModalBody {
    padding: 25px;
    padding-top: 0;
    box-shadow: $shadow-default;
}

.AiHistoryModal__ModalContent {
    margin: 16px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.12);

    .sbs-modal__close-button i {
        font-size: 25px;
    }
}

.AiHistoryModal__ModalContainer {
    right: 0;
    left: unset;
    width: auto;
}

.AiHistoryModal__Header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 20px 0;
    background: $white;
}

.AiHistoryModal__Info {
    margin-bottom: 25px;
}

.AiHistoryModal__DateItems {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.AiHistoryModal__DateItemTitle {
    margin-bottom: 10px;
    color: $gray-deep;

    @include typo-additional-demi;
}

.AiHistoryModal__Items {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.AiHistoryModal__Spinner,
.AiHistoryModal__EmptyStub {
    margin-top: 20%;
}
</style>
