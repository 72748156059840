<template>
    <SbsModal
        v-model="isShowModal"
        class="AiSupportModal"
        width="550">
        <h2 class="AiSupportModal__Title">
            Опишите проблему
        </h2>
        <div class="AiSupportModal__Subtitle">
            Расскажите о проблеме и помогите нам<br>
            сделать ассистент лучше
        </div>
        <AiSupportCombobox
            v-model="problems"
            class="AiSupportModal__Combobox"
            :items="comboboxItems.problemsItems"
            label="В чем была проблема?"
            @update:modelValue="updateProblemCombobox" />
        <AiSupportCombobox
            v-model="dialogThemes"
            class="AiSupportModal__Combobox"
            label="Какая была тема диалога?"
            :items="comboboxItems.dialogThemesItems"
            @update:modelValue="updateThemeCombobox" />
        <SbsTextarea
            v-model="message"
            v-bind="textareaData" />
        <SbsTooltip
            content="Заполните обязательные поля выше, чтобы отправить описание проблемы"
            maxWidth="282"
            fullWidth
            :disabled="!isDisabled">
            <div class="SupportModal__SubmitBtnWrapper">
                <SbsButton
                    class="SupportModal__SubmitBtn"
                    v-bind="submitBtnData"
                    @click="handleSubmit">
                    Отправить
                </SbsButton>
            </div>
        </SbsTooltip>
    </SbsModal>
</template>

<script>
import { getAiSupportProblems, getAiSupportDialogThemes } from '@/api/AiApi.js';
import AiSupportCombobox from './AiSupportCombobox.vue';

const MESSAGE_MIN_LENGTH = 10;

const textareaData = {
    class: 'AiSupportModal__Textarea',
    textareaFieldClass: 'AiSupportModal__TextareaField',
    label: 'Опишите подробнее — необязательно',
    colorTheme: 'primary',
    rows: 7,
    minLength: MESSAGE_MIN_LENGTH,
    fullWidth: true,
};

export default {
    name: 'AiSupportModal',
    components: {
        AiSupportCombobox,
    },
    emits: ['update:modelValue', 'submit'],
    data() {
        return {
            textareaData,
            isLoading: false,
            problems: [],
            dialogThemes: [],
            message: '',
            comboboxItems: {
                problemsItems: [],
                dialogThemesItems: [],
            },
        };
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        isDisabled() {
            return this.message.length < MESSAGE_MIN_LENGTH || !this.problems.length || !this.dialogThemes.length;
        },
        submitBtnData() {
            return {
                loading: this.isLoading || this.submitting,
                disabled: this.isDisabled,
                bordered: false,
                backgroundTheme: 'primary',
                colorTheme: 'white',
                hoverShadow: 'primary',
                static: true,
                fullWidth: true,
                size: 'large',
            };
        },
    },
    methods: {
        setValue(array, field) {
            if (array[1]) {
                this[field] = [array[1]];

                return;
            }

            this[field] = array;
        },
        updateProblemCombobox(arrayValue) {
            this.setValue(arrayValue, 'problems');
        },
        updateThemeCombobox(arrayValue) {
            this.setValue(arrayValue, 'dialogThemes');
        },
        handleSubmit() {
            this.$emit('submit', {
                problems: this.problems,
                dialogThemes: this.dialogThemes,
                message: this.message,
            });
        },
        parseSupportSelectItem(item) {
            return ({
                label: item.title,
                value: item.id,
            });
        },
        getParsedSelectArray(array) {
            return array.map(el => this.parseSupportSelectItem(el));
        },
        async getDataForSelects() {
            this.isLoading = true;

            try {
                const { data: { data: problems } } = await getAiSupportProblems();
                const { data: { data: dialogThemes } } = await getAiSupportDialogThemes();

                this.comboboxItems = {
                    problemsItems: this.getParsedSelectArray(problems),
                    dialogThemesItems: this.getParsedSelectArray(dialogThemes),
                };
            } finally {
                this.isLoading = false;
            }
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        submitting: Boolean,
    },
    watch: {
        isShowModal(val) {
            if (val) {
                this.getDataForSelects();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.AiSupportModal__Title {
    margin-bottom: 10px;
    text-align: center;

    @include typo-h2-bold;
}

.AiSupportModal__Subtitle {
    margin-bottom: 25px;
    color: $gray-deep;
    text-align: center;

    @include typo-additional-demi;
}

.AiSupportModal__Combobox {
    margin-bottom: 10px;
}

.AiSupportModal__Textarea {
    margin-bottom: 25px;
}

.SupportModal__SubmitBtnWrapper {
    width: 100%;
}
</style>

<style lang="scss">
.AiSupportModal {
    .sbs-modal__overlay {
        z-index: 99 !important;
    }

    .sbs-modal__content {
        position: absolute !important;
        top: 0 !important;
        z-index: 100 !important;
    }
}

.AiSupportModal__TextareaField {
    @include typo-primary-book;
}
</style>
