<template>
    <TextBlock
        class="AiQuestionLabel"
        kind="Desktop/font/caption/s-demi">
        <InlineSvg
            v-if="icon"
            :src="icon"
            width="12"
            height="12" />
        {{ label }}
    </TextBlock>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { AiQuestionType, AiQuestionTypeToBlockQustionType } from '@/admin/components/AI/AiModal/contants.js';
import { ICONS_BY_TYPE } from '@/components/Blocks/BlockTestOrQuiz/constants.js';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiQuestionLabel',
    components: {
        InlineSvg,
        TextBlock,
    },
    computed: {
        label() {
            if (this.type === AiQuestionType.OneChoice) return 'Единичный выбор';

            if (this.type === AiQuestionType.MultipleChoice) return 'Множественный выбор';

            return '';
        },
        icon() {
            return ICONS_BY_TYPE[AiQuestionTypeToBlockQustionType[this.type]];
        },
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.AiQuestionLabel {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    padding: 2px 5px;
    color: #74777d;
    background: #d6d9df;
    border-radius: 5px;
}
</style>
