<template>
    <div class="AiGeneratingInfo">
        <DotsLoader class="AiGeneratingInfo__Icon" />
        <TextBlock
            class="AiGeneratingInfo__Label"
            kind="Desktop/font/body/s1-medium">
            Идет процесс генерации…
        </TextBlock>
    </div>
</template>

<script>
import DotsLoader from '@/components/DotsLoader.vue';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiGeneratingInfo',
    components: {
        TextBlock,
        DotsLoader,
    },
};
</script>

<style lang="scss" scoped>
.AiGeneratingInfo {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #8c47e4;
}

.AiGeneratingInfo__Icon {
    --dot-size: 7px;
}
</style>
