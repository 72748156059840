import axios from 'axios';
import { Utils } from '@sbs/uikit-constructor';
import { BASE_URI, BASE_URI_DEVELOPMENT_CENTER } from '@/api/CONST.js';

const prefix = BASE_URI_DEVELOPMENT_CENTER;

/**
 * get all attachmets for page activity
 * @param {number|string} activityId activity id
 * @returns Promise
 */
export const getActivityAttachments = activityId => axios.get(`${prefix}/activities/${activityId}/attachments`);

/**
 * get attachment
 * @param {number|string} attachmentId
 * @param {number|string} activityId
 * @returns {Promise}
 */
export const getAttachment = ({ activityId, attachmentId }) => axios.get(`${prefix}/activities/${activityId}/attachments/${attachmentId}`);

/**
 * add attachment to activity
 *
 * @typedef {object} RespAttach
 * @property {boolean} success
 * @property {object} data
 * @property {number} data.id
 * @property {string} data.name
 * @property {number} data.sort
 * @property {string} data.file_mime
 * @property {number} data.file_size
 * @property {string} data.file_hash
 * @property {string} data.file_name
 * @property {string} data.file
 * @property {string} data.temp_url
 * @property {string} data.created_at
 * @property {string} data.updated_at
 */

/**
 * @param {number|string} activityId activity id
 * @param {object} attachmentData attachment
 * @param {string} attachmentData.name attachment name
 * @param {File} attachmentData.file attachment file
 * @param {function} [callback] upload process callback
 * @returns Promise<import('axios').AxiosResponse<RespAttach>>
 */
export const addActivityAttachment = (activityId, attachmentData, callback) => {
    const formData = new FormData();
    const { file, name } = attachmentData;

    formData.append('file', file);

    if (name) formData.append('name', name);

    return axios.post(`${prefix}/activities/${activityId}/attachments`, formData, {
        onUploadProgress: progressEvent => {
            if (Utils.Helpers.toRawType(callback) === 'Function') {
                callback(progressEvent);
            }
        },
    });
};

/**
 * remove activity attachment
 * @param {number|string} pageId page id
 * @param {number|string} activityId activity id
 * @param {number|string} attachmentId attachment id
 * @returns Promise
 */
export const removeActivityAttachment = (activityId, attachmentId) => axios.delete(`${prefix}/activities/${activityId}/attachments/${attachmentId}`);

/**
 * restore activity attachment
 * @param {number|string} activityId activity id
 * @param {number|string} attachmentId attachment id
 * @returns Promise
 */
export const restoreActivityAttachment = (activityId, attachmentId) => axios.post(`${prefix}/activities/${activityId}/attachments/${attachmentId}/restore`);

/**
 * @param {number|string} activityId activity id
 * @param {number|string} attachmentId attachment id
 * @return {Promise<AxiosResponse<any>>}
 */
export const duplicateActivityAttachment = (activityId, attachmentId) => axios.post(`${prefix}/activities/${activityId}/attachments/${attachmentId}/copy`);

/**
 * return file name from url
 * @param {string} url
 * @returns string
 */
const getUrlFilename = url => url.substring(url.lastIndexOf('/') + 1);

const forceFileDownloadLink = (url, fileName) => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', fileName || '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadUrl = (url, name = getUrlFilename(url)) => {
    const newUrl = new URL(url, window.location.origin);

    newUrl.searchParams.append('download', true);

    forceFileDownloadLink(newUrl.toString(), name);
};

export const getFilenameFromContentDisposition = contentDisposition => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    const fileName = matches[1].replace(/['"]/g, '');

    return fileName;
};

/**
 * get frame from video
 * @param {string|number} id attacment id
 * @param {string|number} seconds video episode seconds
 * @param {string|number} width attacment width
 * @param {string|number} height attacment height
 * @param {string|number} format attacment (jpg, webp)
 * @param signal axios signal token
 * @param {string|number} format attacment (jpg, webp)
 */
export const getFrameFromVideo = ({
    id,
    seconds,
    width = 85,
    height = 48,
    format = 'jpg',
}, { signal } = {}) => axios.get(`${BASE_URI}/attachments/${id}/frame`, {
    signal,
    responseType: 'blob',
    params: {
        seconds,
        width,
        height,
        format,
    },
});

export const getFrameFromVideoSrc = ({
    id,
    seconds,
    width = 85,
    height = 48,
    format = 'jpg',
}) => {
    const params = new URLSearchParams({
        seconds,
        width,
        height,
        format,
    });

    return `${BASE_URI}/attachments/${id}/frame?${params.toString()}`;
};

/**
 * get attachment original file by attachment id and filename
 * @param {number|string} attachmentId attachment id
 * @param {string} filename attachment file name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAttachmentFileByFilename = ({ attachmentId, filename }) => axios.get(`${BASE_URI}/attachments/${attachmentId}/serve/${filename}`);

/**
 * @param params
 * @param params.activityId
 * @param params.attachmentId
 * @return {Promise<AxiosResponse<any>>}
 */
export const startGenerateConversions = ({ activityId, attachmentId }) => axios.post(`${prefix}/activities/${activityId}/attachments/${attachmentId}/generate-conversions`);
