<template>
    <div class="AiHistoryPopupItem">
        <slot>
            {{ text }}
        </slot>
    </div>
</template>

<script>
export default {
    name: 'AiHistoryPopupItem',
    props: {
        text: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.AiHistoryPopupItem {
    display: inline-block;
    align-self: flex-end;
    max-width: 253px;
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.12);
    cursor: pointer;

    @include typo-subsecondary-demi;

    &:hover {
        //color: $gray-deep;
        background: #ffffff;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
    }
}
</style>
