const iconSingleChoice = require('@/assets/svg/icon-single-choice.inline.svg');
const iconMultipleAnswers = require('@/assets/svg/icon-multiple-answers.inline.svg');
const iconTextMatch = require('@/assets/svg/icon-text-match.inline.svg');
const iconSort = require('@/assets/svg/icon-sort-answers.inline.svg');
const iconLikertScale = require('@/assets/svg/icon-likert-scale.inline.svg');
const iconOpenQuestion = require('@/assets/svg/icon-open-question.inline.svg');
const iconMatching = require('@/assets/svg/icons-arrow-list.inline.svg');
const iconInfo = require('@/assets/svg/icons8-info-alt.inline.svg');
const iconDistrubCategory = require('@/assets/svg/ic_category-outlined.svg');
const iconQuizCards = require('@/assets/svg/ic_double_details_card.svg');
const iconSlots = require('@/assets/svg/ic_versions-outline.svg');
const iconFile = require('@/assets/svg/icon-file.svg');

export const QUESTION_TYPES = Object.freeze({
    SINGLE_CHOICE: 'OnlyChoice',
    MULTIPLE_ANSWERS: 'MultipleChoice',
    LIKERT_SCALE: 'LikertScale',
    OPEN_QUESTION: 'OpenQuestion',
    SORTING: 'Sorting',
    MATCHING: 'Matching',
    GAPS_IN_TEXT: 'GapsInTheText',
    INFORMATION: 'Information',
    DISTRIBUTE_BY_CATEGORY: 'DistributionByCategory',
    QUIZ_CARDS: 'QuizCards',
    SLOTS: 'Slots',
    FILE: 'File',
});

export const QUESTION_TYPES_INTERVIEW = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.LIKERT_SCALE,
    QUESTION_TYPES.OPEN_QUESTION,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.QUIZ_CARDS,
    QUESTION_TYPES.INFORMATION,
    QUESTION_TYPES.SLOTS,
    QUESTION_TYPES.FILE,
];

export const QUESTION_TYPES_TEST = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY,
    QUESTION_TYPES.QUIZ_CARDS,
    QUESTION_TYPES.INFORMATION,
];

export const TestQuizTypes = Object.freeze({
    TEST: 'test',
    INTERVIEW: 'interview',
});

export const TestOrQuizTitle = {
    [TestQuizTypes.TEST]: 'Тест',
    [TestQuizTypes.INTERVIEW]: 'Опрос',
};

export const MicroTestOrQuizTitle = {
    [TestQuizTypes.TEST]: 'Микротест',
    [TestQuizTypes.INTERVIEW]: 'Микроопрос',
};

export const REACTION_TYPES = {
    NONE: 'none',
    EVERY: 'every',
    OVERALL: 'overall',
};

export const LIKERT_SCALE_TYPES = {
    NUMBER: 'number',
    TEXT: 'text',
    TEXT_WITH_NUMBER: 'text-with-number',
};

export const QuestionsResultsType = Object.freeze({
    USER_ONLY: 'userOnly',
    USER_AND_RIGHT: 'userAndRight',
    NONE: 'none',
});

export const SummaryReactionTypes = Object.freeze({
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
});

export const QUESTION_TYPES_TEXT = Object.freeze({
    [QUESTION_TYPES.SINGLE_CHOICE]: 'Единичный выбор',
    [QUESTION_TYPES.MULTIPLE_ANSWERS]: 'Множественный выбор',
    [QUESTION_TYPES.LIKERT_SCALE]: 'Шкала Ликерта',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Открытый вопрос',
    [QUESTION_TYPES.SORTING]: 'Сортировка',
    [QUESTION_TYPES.MATCHING]: 'Установка соответствия',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Пропуски в тексте',
    [QUESTION_TYPES.INFORMATION]: 'Информационный слайд',
    [QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY]: 'Распределение по категориям',
    [QUESTION_TYPES.QUIZ_CARDS]: 'Квиз-карточки',
    [QUESTION_TYPES.SLOTS]: 'Слоты',
    [QUESTION_TYPES.FILE]: 'Загрузить файл',
});

export const QUESTION_LABEL_TEXT = {
    [QUESTION_TYPES.SINGLE_CHOICE]: 'Выберите один вариант ответа',
    [QUESTION_TYPES.MULTIPLE_ANSWERS]: {
        [TestQuizTypes.TEST]: 'Выберите несколько вариантов ответа',
        [TestQuizTypes.INTERVIEW]: 'Выберите несколько вариантов ответа',
    },
    [QUESTION_TYPES.LIKERT_SCALE]: 'Отметьте на шкале наиболее подходящий вариант ответа',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Запишите свой ответ в поле ввода',
    [QUESTION_TYPES.SORTING]: 'Расставьте тезисы в правильном порядке',
    [QUESTION_TYPES.MATCHING]: 'Сопоставьте пары для элементов (может быть несколько соответствий для одного элемента)',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Заполните пропуски в тексте',
    [QUESTION_TYPES.INFORMATION]: 'Информационный слайд',
    [QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY]: 'Распределите карточки по категориям',
    [QUESTION_TYPES.QUIZ_CARDS]: 'Определите верный вариант карточки',
    [QUESTION_TYPES.SLOTS]: 'Выберите только один слот',
    [QUESTION_TYPES.FILE]: 'Добавьте файл',
};

export const QUESTION_TYPES_FOR_DISABLE_SHAKE_ANSWERS = [
    QUESTION_TYPES.LIKERT_SCALE,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.OPEN_QUESTION,
];

export const MAX_TESTQUIZ_TITLE_LENGTH = 175;
export const MAX_TEST_DESCRIPTION_LENGTH = 2000;
export const MAX_QUIZ_DESCRIPTION_LENGTH = 500;
export const MAX_QUESTIONS = 100;
export const MAX_HOURS = 12;
export const MAX_QUESTION_TITLE = 175;
export const MAX_QUESTION_DESCRIPTION = 370;
export const MAX_INFORMATION_DESCRIPTION = 4000;
export const MAX_ANSWERS = 10;
export const MIN_ANSWERS = 2;
export const MIN_QUIZ_CARDS = 2;
export const MAX_QUIZ_CARDS = 4;
export const MAX_MATCH_ITEMS_NUMBER = 20;
export const MIN_MATCH_ITEMS_NUMBER = 2;
export const MIN_ANSWERS_GAP_IN_TEXT = 1;
export const MAX_LENGTH_TEXT_GAP_IN_TEXT = 3000;
export const MAX_LENGTH_ANSWER = 300;
export const MAX_LENGTH_COMMENT = 300;
export const MAX_LENGTH_ANSWER_LIKERT = 50;
export const MAX_LENGTH_ANSWER_MATCHTEXT = 100;
export const MAX_LENGTH_ANSWER_GAPSTEXT = 50;
export const MAX_SLOTS_QUANTITY = 6;
export const MIN_SLOTS_QUANTITY = 2;

export const MAX_LENGTH_ANSWER_CATEGORY_TITLE = 50;
export const MIN_LENGTH_ANSWER_CATEGORY_ITEMS = 2;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEMS = 4;

export const MAX_LENGTH_ANSWER_CATEGORY_COUNT = 4;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_TEXT = 100;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_COUNT_MIN = 1;
export const MAX_LENGTH_ANSWER_CATEGORY_ITEM_COUNT_MAX = 24;
export const MIN_ANSWER_MATCHING_COUNT = 4;
export const MIN_ANSWER_LEVEL = 1;
export const DEFAULT_LIKERT_ANSWERS_LENGTH = 5;

export const MAX_LENGTH_ANSWER_QUIZCARD_TEXT = 100;

export const MAX_SLOTS_TITLE_LENGTH = 100;
export const MAX_SLOTS_PLACES_LENGTH = 3;

export const MATCHING_QUESTION_COLUMN_TYPES = {
    RIGHT: 'right',
    LEFT: 'left',
};

export const SHAKE_ANSWER_DISABLED_TOOLTIP = {
    default: 'Выдавать ответы учащимся в случайном порядке',
    [QUESTION_TYPES.LIKERT_SCALE]: 'Нельзя задать данный параметр когда выбрана шкала Ликерта',
    [QUESTION_TYPES.OPEN_QUESTION]: 'Нельзя задать данный параметр когда выбран открытый вопрос',
    [QUESTION_TYPES.SORTING]: 'Нельзя выключить данный параметр когда выбрана сортировка',
    [QUESTION_TYPES.GAPS_IN_TEXT]: 'Нельзя задать данный параметр когда выбрано дополнение к тексту',
};

export const QUESTION_TYPES_WITH_BLOCK_TYPE = {
    [TestQuizTypes.TEST]: [
        QUESTION_TYPES.SINGLE_CHOICE,
        QUESTION_TYPES.MULTIPLE_ANSWERS,
        QUESTION_TYPES.SORTING,
        QUESTION_TYPES.GAPS_IN_TEXT,
    ],
    [TestQuizTypes.INTERVIEW]: [
        QUESTION_TYPES.SINGLE_CHOICE,
        QUESTION_TYPES.MULTIPLE_ANSWERS,
        QUESTION_TYPES.SORTING,
        QUESTION_TYPES.LIKERT_SCALE,
        QUESTION_TYPES.OPEN_QUESTION,
    ],
};

export const VISIBILITY_SHUFFLE_ANSWERS = [
    QUESTION_TYPES.SINGLE_CHOICE,
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.SORTING,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.QUIZ_CARDS,
];

export const VISIBILITY_MIN_ANSWERS = [
    QUESTION_TYPES.MULTIPLE_ANSWERS,
    QUESTION_TYPES.GAPS_IN_TEXT,
    QUESTION_TYPES.MATCHING,
    QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY,
];

export const DistributionCategoryFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};

export const DistributionCategoryCardFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};

export const QuizCardFormats = {
    TEXT: 'text',
    IMAGE: 'image',
    TEXT_WITH_IMAGE: 'text_with_image',
};

export const QuizFileTypes = {
    ARCHIVE: 'archive',
    AUDIO: 'audio',
    VIDEO: 'video',
    DOC: 'doc',
    IMAGE: 'image',
    PRESENTATION: 'presentation',
    TABLE: 'table',
};

export const quizFileTypesData = [
    {
        label: 'Архив',
        value: QuizFileTypes.ARCHIVE,
        extensions: [
            '7z',
            'ace',
            'bz2',
            'crx',
            'gz',
            'gzip',
            'rar',
            'tar',
            'tgz',
            'zip',
            'zipx',
        ],
    },
    {
        label: 'Аудио',
        value: QuizFileTypes.AUDIO,
        extensions: [
            '3ga',
            'aac',
            'aif',
            'aifc',
            'aiff',
            'amr',
            'ape',
            'asx',
            'flac',
            'm4a',
            'm4p',
            'mid',
            'midi',
            'mp2',
            'mp3',
            'mpa',
            'ogg',
            'wav',
            'wma',
        ],
    },
    {
        label: 'Видео',
        value: QuizFileTypes.VIDEO,
        extensions: [
            '3g2',
            '3gp',
            '3gpp',
            'asf',
            'avi',
            'divx',
            'flv',
            'h264',
            'm2ts',
            'm4v',
            'mkv',
            'mov',
            'mp4',
            'mpeg',
            'mpg',
            'mts',
            'mxf',
            'ogv',
            'qt',
            'rm',
            'swf',
            'vob',
            'webm',
            'wmv',
        ],
    },
    {
        label: 'Документ',
        value: QuizFileTypes.DOC,
        extensions: [
            'csv',
            'djv',
            'djvu',
            'doc',
            'docm',
            'docx',
            'dvi',
            'eps',
            'fb2',
            'fodg',
            'fodp',
            'fods',
            'fodt',
            'htm',
            'html',
            'ipynb',
            'key',
            'md',
            'msg',
            'odf',
            'odg',
            'odm',
            'odp',
            'ods',
            'odt',
            'oxps',
            'pdf',
            'pps',
            'ppsx',
            'ppt',
            'pptm',
            'pptx',
            'ps',
            'pub',
            'rmp',
            'rtf',
            'tex',
            'txt',
            'vdx',
            'vsx',
            'vtt',
            'vtx',
            'xls',
            'xlsm',
            'xlsx',
            'xml',
            'xps',
            'xsn',
        ],
    },
    {
        label: 'Изображение',
        value: QuizFileTypes.IMAGE,
        extensions: [
            'ai',
            'bmp',
            'cdr',
            'cvs',
            'emf',
            'gif',
            'jpeg',
            'jpg',
            'mac',
            'pcx',
            'png',
            'psd',
            'svg',
            'tga',
            'tif',
            'tiff',
            'xcf',
            'xlr',
            'jfif',
            'webp',
        ],
    },
    {
        label: 'Презентация',
        value: QuizFileTypes.PRESENTATION,
        extensions: [
            'odp',
            'pdf',
            'pps',
            'ppsx',
            'ppt',
            'pptm',
            'pptx',
        ],
    },
    {
        label: 'Таблица',
        value: QuizFileTypes.TABLE,
        extensions: [
            'csv',
            'json',
            'ods',
            'xls',
            'xlsm',
            'xlsx',
        ],
    },
];

export const HEX_ICON_COLORS = {
    black: '#000000',
    primary: '#0066FF',
    gray: '#74777D',
};

export const ICONS_BY_TYPE = {
    [QUESTION_TYPES.SINGLE_CHOICE]: iconSingleChoice,
    [QUESTION_TYPES.MULTIPLE_ANSWERS]: iconMultipleAnswers,
    [QUESTION_TYPES.GAPS_IN_TEXT]: iconTextMatch,
    [QUESTION_TYPES.SORTING]: iconSort,
    [QUESTION_TYPES.LIKERT_SCALE]: iconLikertScale,
    [QUESTION_TYPES.OPEN_QUESTION]: iconOpenQuestion,
    [QUESTION_TYPES.MATCHING]: iconMatching,
    [QUESTION_TYPES.DISTRIBUTE_BY_CATEGORY]: iconDistrubCategory,
    [QUESTION_TYPES.QUIZ_CARDS]: iconQuizCards,
    [QUESTION_TYPES.INFORMATION]: iconInfo,
    [QUESTION_TYPES.SLOTS]: iconSlots,
    [QUESTION_TYPES.FILE]: iconFile,
};
