<template>
    <div class="AiIncorrectAnswers">
        <TextBlock
            class="AiIncorrectAnswers__Title"
            kind="Desktop/font/body/s1-demi">
            Отметьте варианты, которые хотите добавить
        </TextBlock>
        <div class="AiIncorrectAnswers__List">
            <div
                v-for="(answer, ind) in answers"
                :key="ind"
                class="AiIncorrectAnswers__Item">
                <TextBlock
                    class="AiIncorrectAnswers__Number"
                    kind="Desktop/font/body/s1-medium">
                    {{ ind + 1 }}.
                </TextBlock>
                <TextBlock
                    class="AiIncorrectAnswers__Text"
                    kind="Desktop/font/body/s1-book">
                    {{ answer }}
                </TextBlock>
                <div class="AiIncorrectAnswers__BtnWrap">
                    <SbsTooltip
                        position="bottom-end"
                        content="Копировать">
                        <SbsButtonIcon
                            class="AiIncorrectAnswers__CopyBtn"
                            borderTheme="white"
                            backgroundTheme="white"
                            hoverBackground="white"
                            hoverBorder="primary"
                            bordered
                            hoverShadow="gray"
                            :expandClickArea="false"
                            @click="handleClickCopy(answer)">
                            <InlineSvg
                                class="AiIncorrectAnswers__Icon"
                                :src="require('@/assets/svg/icons8-copy.inline.svg')" />
                        </SbsButtonIcon>
                    </SbsTooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { useClipboard } from '@vueuse/core';
import useSnackbar from '@/hooks/snackbars.js';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiIncorrectAnswers',
    components: {
        InlineSvg,
        TextBlock,
    },
    methods: {
        async handleClickCopy(answer) {
            try {
                await this.clipboard.copy(answer);
                this.createSnackbar({
                    type: 'success',
                    message: 'Скопировано в буфер обмена',
                });
            } catch (e) {
                console.error(e);
                this.createSnackbar({
                    type: 'error',
                    message: 'Не удалось скопировать. Попробуй еще раз.',
                });
            }
        },
    },
    props: {
        answers: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        const { createSnackbar } = useSnackbar();
        const clipboard = useClipboard();

        return {
            clipboard,
            createSnackbar,
        };
    },
};
</script>

<style lang="scss" scoped>
.AiIncorrectAnswers {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.AiIncorrectAnswers__CopyBtn {
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
}

.AiIncorrectAnswers__List {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.AiIncorrectAnswers__Item {
    display: flex;
    gap: 10px;
    padding: 15px;
    background-color: #f4f5f8;
    border-radius: 15px;
}

.AiIncorrectAnswers__Number {
    flex-shrink: 0;
}

.AiIncorrectAnswers__Text {
    flex-grow: 1;
    min-width: 0;
}

.AiIncorrectAnswers__BtnWrap {
    flex-shrink: 0;
}

.AiIncorrectAnswers__Icon {
    width: 16px;
    height: 16px;
}
</style>
