<template>
    <AiQuestionBase
        class="AiQuestionSingle"
        :question="question">
        <div class="AiQuestionSingle__List">
            <div
                v-for="(answer, ind) in answers"
                :key="ind"
                class="AiQuestionSingle__Answer">
                <div class="AiQuestionSingle__ControlWrap">
                    <SbsRadio
                        class="AiQuestionSingle__AnswerControl"
                        disabled
                        :checked="answer.is_correct" />
                </div>
                <TextBlock
                    class="AiQuestionSingle__AnswerTitle"
                    kind="Desktop/font/body/s1-book">
                    {{ answer.title }}
                </TextBlock>
            </div>
        </div>
    </AiQuestionBase>
</template>

<script>
import AiQuestionBase from '@/admin/components/AI/AiModal/AiQuestionBase.vue';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiQuestionSingle',
    components: {
        TextBlock,
        AiQuestionBase,
    },
    computed: {
        answers() { return this.question?.answers ?? []; },
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.AiQuestionSingle__List {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.AiQuestionSingle__Answer {
    display: flex;
    gap: 10px;
}

.AiQuestionSingle__ControlWrap {
    flex-shrink: 0;
    padding: 3px 0;
}

.AiQuestionSingle__AnswerControl {
    .sbs-radio__item {
        padding: 0;
    }

    .sbs-radio__control-wrapper {
        margin: 0;
    }

    &.sbs-radio.disabled.checked .sbs-radio__control {
        background: rgba(#c4c6cd, 0.4);
        border-color: transparent;
    }
}

.AiQuestionSingle__AnswerTitle {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
}
</style>
