import { QUESTION_TYPES } from '@/components/Blocks/BlockTestOrQuiz/constants.js';

export const AiQuestionType = {
    OneChoice: 'OnlyChoice',
    MultipleChoice: 'MultipleChoice',
};

export const AiQuestionTypeToBlockQustionType = {
    [AiQuestionType.OneChoice]: QUESTION_TYPES.SINGLE_CHOICE,
    [AiQuestionType.MultipleChoice]: QUESTION_TYPES.MULTIPLE_ANSWERS,
};

export const AiMessageAuthorType = {
    ASSISTANT: 'assistant',
    USER: 'user',
};
