import { nextTick, ref } from 'vue';

/**
 * @param {import('vue').Ref} scrollWrapRef
 * @returns {{
 *  scrollSave: ((function(): Promise<void>)|*),
 *  scrollRestore: ((function(): Promise<void>)|*),
 *  handleScroll: (function():void)
 * }}
 */
export const useScrollSaver = scrollWrapRef => {
    const scrollTop = ref(0);
    const scrollHeight = ref(0);

    const osInstance = () => scrollWrapRef.value?.osInstance?.();

    const getScrollHeight = () => osInstance()?.elements().viewport.scrollHeight;

    const scrollSave = async () => {
        await nextTick();
        scrollHeight.value = getScrollHeight();
    };

    const scrollRestore = async () => {
        await nextTick();

        if (osInstance() && !scrollTop.value) {
            scrollWrapRef.value?.scroll({
                top: getScrollHeight() - scrollHeight.value,
            });
        }
    };

    const handleScroll = event => {
        scrollTop.value = event.target.scrollTop;
    };

    return {
        scrollSave,
        scrollRestore,
        handleScroll,
    };
};
