<template>
    <div class="AiMessageAssistant">
        <AiModalResult
            v-if="textResult"
            :result="textResult"
            :typing="isLast && !message.is_viewed"
            @start="handleTypingStart"
            @complete="handleTypingComplete" />
        <template v-if="!isTypingResult">
            <AiQueryLinks
                v-if="links.length"
                :links="links" />
            <AiIncorrectAnswers
                v-if="incorrectAnswers?.length"
                :answers="incorrectAnswers" />
            <AiQuestions
                v-if="questions?.length"
                :questions="questions" />

            <AiModalActionBtns
                class="AiMessageAssistant__Actions"
                :actions="actions"
                :grade="grade"
                @try:again="handleRetry"
                @like="handleClickLike"
                @dislike="handleClickDislike"
                @copy="handleCopy" />
        </template>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { getRenderMarkdownResult } from '@/utils/helpers.js';
import { getAiMessageText } from '@/admin/components/AI/AiModal/helpers.js';
import { AiMessagesPromptTypes } from '@/constants/AI.js';
import AiModalResult from '@/admin/components/AI/AiModal/AiModalResult.vue';
import AiIncorrectAnswers from '@/admin/components/AI/AiModal/AiIncorrectAnswers.vue';
import AiQuestions from '@/admin/components/AI/AiModal/AiQuestions.vue';
import AiQueryLinks from '@/admin/components/AI/AiModal/AiQueryLinks.vue';
import AiModalActionBtns from '@/admin/components/AI/AiModal/AiModalActionBtns.vue';

export default defineComponent({
    name: 'AiMessageAssistant',
    components: {
        AiModalActionBtns,
        AiQueryLinks,
        AiQuestions,
        AiIncorrectAnswers,
        AiModalResult,
    },
    emits: ['typing:start', 'typing:complete', 'like', 'dislike', 'copy', 'retry'],
    data() {
        return {
            grade: null,
            isTypingResult: false,
        };
    },
    computed: {
        textResult() {
            const text = getAiMessageText(this.message);

            // TODO возможно рендерить текст нужно только для определенных типов результатов
            // if (this.message.prompt_type ?????)
            const renderedText = getRenderMarkdownResult(text);

            return renderedText;
        },
        links() {
            return [];
        },
        incorrectAnswers() {
            return this.message.incorrect_answers ?? [];
        },
        questions() {
            return this.message.test_questions ?? [];
        },
        actions() {
            return {
                reactions: true,
                refresh: this.isLast,
                // TODO реализовать, пока сложно реализовать массовое создание различных типов ответов
                copy: this.message.prompt_type !== AiMessagesPromptTypes.GENERATE_INCORRECT_ANSWERS,
            };
        },
    },
    methods: {
        handleClickLike() {
            if (this.grade === 'like') return;

            this.$emit('like', this.message);
            this.grade = 'like';
        },
        handleClickDislike() {
            if (this.grade === 'dislike') return;

            this.$emit('dislike', {
                message: this.message,
                callback: () => {
                    this.grade = 'dislike';
                },
            });
        },
        handleCopy() {
            this.$emit('copy', this.message);
        },
        handleRetry() {
            this.$emit('retry', this.message);
        },
        handleTypingStart() {
            this.isTypingResult = true;
            this.$emit('typing:start');
        },
        handleTypingComplete() {
            this.isTypingResult = false;
            this.$emit('typing:complete');
        },
    },
    props: {
        /**
         * @type {import('vue').PropType<{
         *       id: number,
         *       prompt_type: 'improve_writing' | 'correct_writing' | 'make_shorter' | 'make_longer' | 'summarize' | 'generate_image' | 'generate_incorrect_answers' | 'make_question' | 'generate_consolidate_material_task' | 'generate_testing_skills_task' | 'generate_reflection_on_material_task' | 'generate_test',
         *       author: 'user' | 'assistant' | 'system',
         *       text: string,
         *       status: 'created' | 'processing' | 'processed' | 'canceled' | 'failed',
         *       updated_at: string,
         *       is_viewed: boolean,
         *       incorrect_answers: Array<string>,
         *       test_questions: Array<{
         *           type: 'OnlyChoice' | 'MultipleChoice',
         *           title: string,
         *           description: string,
         *           answers: Array<{
         *               title: string,
         *               is_correct: boolean
         *             }>
         *         }>
         *     }>}
         */
        message: {
            type: Object,
            required: true,
        },
        isLast: Boolean,
    },
});
</script>

<style lang="scss">
.AiMessageAssistant__Actions {
    margin-top: 10px;
}
</style>
