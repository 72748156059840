<template>
    <div class="AiHistoryPopupWrap">
        <SbsPopup
            v-if="!isShowModal && !isShowModalResult"
            v-model="isShowPopup"
            class="AiHistoryPopup"
            popupClass="AiHistoryPopup__Popup"
            position="top-end"
            strategy="fixed"
            offset="10"
            :triggers="isHistoryListExist ? ['hover'] : []"
            :hideTriggers="[]"
            :closeOnClickOutside="false"
            :showCloseButton="false">
            <template #content-popper>
                <AiHistoryPopupCloseBtn
                    class="AiHistoryPopup__BtnClose"
                    @click="setShowPopup(false)" />
                <div class="AiHistoryPopup__History">
                    <AiHistoryPopupItem
                        v-for="item in displayedHistoryList"
                        :key="item.id"
                        :text="getItemsText(item)"
                        @click="handleClickHistoryItem(item)" />
                </div>
            </template>
            <AiHistoryPopupBtn
                :withNotify="!!historySessionsNotViewedCount"
                @click="handleShowModal" />
        </SbsPopup>
        <AiHistoryModal v-model="isShowModal" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { userGetterTypes } from '@/store/modules/user.js';
import { getAiHistoryMessageText } from '@/admin/components/AI/AiModal/helpers.js';
import AiHistoryModal from '../AiHistoryModal/AiHistoryModal.vue';
import AiHistoryPopupBtn from './components/AiHistoryPopupBtn.vue';
import AiHistoryPopupCloseBtn from './components/AiHistoryPopupCloseBtn.vue';
import AiHistoryPopupItem from './components/AiHistoryPopupItem.vue';

export default {
    name: 'AiHistoryPopup',
    components: {
        AiHistoryPopupBtn,
        AiHistoryPopupCloseBtn,
        AiHistoryPopupItem,
        AiHistoryModal,
    },
    data: () => ({
        isShowPopup: false,
    }),
    computed: {
        ...mapState('AI', {
            isShowModalHistory: 'isShowModalHistory',
            isShowModalResult: 'isShowModal',
        }),
        ...mapGetters('AI', [
            'newHistorySessionsList',
            'historySessionsNotViewedCount',
        ]),
        ...mapGetters('user', {
            user: userGetterTypes.GET_USER,
        }),
        isHistoryListExist() {
            return Boolean(this.newHistorySessionsList.length);
        },
        displayedHistoryList() {
            return this.newHistorySessionsList;
        },
        isShowModal: {
            get() { return this.isShowModalHistory; },
            set(val) { this.setShowModalHistory(val); },
        },
    },
    methods: {
        ...mapActions('AI', [
            'showModalAiBySessionId',
            'getAiNewHistorySessions',
            'stopAiManageUsersSocketConnection',
            'initAiManageUsersSocketConnection',
        ]),
        ...mapMutations('AI', ['setShowModalHistory']),
        handleClickHistoryItem(item) {
            this.showModalAiBySessionId({
                sessionId: item.id,
            });
        },
        handleShowModal() {
            this.isShowModal = true;
        },
        setShowPopup(isShow) {
            this.isShowPopup = isShow;
        },
        getItemsText(item) {
            return getAiHistoryMessageText(item);
        },
    },
    watch: {
        '$route.params.developmentCenterId'(developmentCenterId, prevDevelopmentCenterId) {
            const socketData = {
                developmentCenterId,
                userId: this.user.id,
            };

            if (prevDevelopmentCenterId && prevDevelopmentCenterId !== developmentCenterId) {
                this.stopAiManageUsersSocketConnection(socketData);
            }

            this.getAiNewHistorySessions({ developmentCenterId });
            this.initAiManageUsersSocketConnection(socketData);
        },
        isShowModal(val) {
            if (val) {
                this.setShowPopup(false);
            }
        },
        isShowModalResult(val) {
            if (val) {
                this.setShowPopup(false);
            }
        },
    },
};
</script>


<style lang="scss">
.DefaultLayout:has(.AddButton, .AdminView__ScrollButton--Visible) {
    .AiHistoryPopupWrap {
        bottom: 80px;
    }
}

.AiHistoryPopupWrap {
    position: fixed;
    right: 15px;
    bottom: 15px;
}

.AiHistoryPopup__Popup {
    padding: 0;
    background: transparent;
    box-shadow: none;
}

.AiHistoryPopup__BtnClose {
    margin-bottom: 15px;
    margin-left: auto;
}

.AiHistoryPopup__History {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 1px;
}
</style>

