import cloneDeep from 'lodash/cloneDeep.js';
import * as api from '@/api/AiApi.js';
import { getEchoInstance } from '@/services/echo.js';
import useSnackbar from '@/hooks/snackbars.js';
import { getNewBlockInstance } from '@/utils/utils.js';
import { AiMessageStatus } from '@/constants/AI.js';

const { createSnackbar } = useSnackbar();

const MAX_DISPLAYED_HISTORY_ITEMS_COUNT = 3;

export default {
    namespaced: true,
    state: {
        isShowModal: false,
        isShowModalHistory: false,
        isSendingMessage: false,
        isError: false,
        session: null,
        messages: {
            loading: false,
            list: [],
            meta: {},
        },
        copiedQuestions: null,
        copiedIncorrectAnswers: null,

        socketInstance: null,

        historySessionsList: [],
        newHistorySessionsList: [],
        historySessionsLoading: false,
        currentHistorySessionsPage: 1,
        historySessionsLastPage: 1,
        historySessionsNotViewedCount: 0,
    },
    getters: {
        isShowModal: state => state.isShowModal,
        aiSession: state => state.session,
        messages: state => state.messages,
        messagesList: state => state.messages.list,
        messagesNextCursor: state => state.messages.meta?.next_cursor,
        historySessionsList: state => state.historySessionsList,
        newHistorySessionsList: state => state.newHistorySessionsList,
        historySessionsLoading: state => state.historySessionsLoading,
        currentHistorySessionsPage: state => state.currentHistorySessionsPage,
        historySessionsLastPage: state => state.historySessionsLastPage,
        historySessionsNotViewedCount: state => state.historySessionsNotViewedCount,
    },
    mutations: {
        setShowModal(state, value) {
            state.isShowModal = value;

            if (value) {
                state.isShowModalHistory = false;
            }
        },
        setShowModalHistory(state, value) {
            state.isShowModalHistory = value;

            if (value) {
                state.isShowModal = false;
            }
        },
        setAiSession(state, session) {
            state.session = session;
        },
        clearMessages(state) {
            state.messages.list = [];
        },
        /**
         * @param {*} state
         * @param {boolean} value
         */
        setLoadingMessages(state, value) {
            state.messages.loading = value;
        },
        /**
         * @param {*} state
         * @param {boolean} value
         */
        setIsSendingMessage(state, value) {
            state.isSendingMessage = value;
        },
        /**
         * @param {*} state
         * @param {object} payload
         * @param {Array} payload.sessionId
         * @param {Array} payload.messages
         * @param {object} payload.meta
         */
        addMessages(state, payload) {
            const { messages, meta } = payload;

            state.messages.list = [
                ...messages.toReversed(),
                ...state.messages.list,
            ];

            state.messages.meta = meta;
        },
        /**
         * @param {*} state
         * @param {object} payload
         * @param {Array} payload.sessionId
         * @param {Array} payload.message
         */
        pushMessage(state, payload) {
            const { message, sessionId } = payload;

            if (!state.session || state.session?.id !== sessionId) return;

            state.messages.list = [
                ...state.messages.list,
                message,
            ];
        },
        setSocketInstance(state, socketInstance) {
            state.socketInstance = socketInstance;
        },
        setHistorySessionsList(state, value) {
            state.historySessionsList = value;
        },
        setNewHistorySessionsList(state, value) {
            state.newHistorySessionsList = value;
        },
        setHistorySessionsLoading(state, value) {
            state.historySessionsLoading = value;
        },
        setCurrentHistorySessionsPage(state, value) {
            state.currentHistorySessionsPage = value;
        },
        setHistorySessionsLastPage(state, value) {
            state.historySessionsLastPage = value;
        },
        setHistorySessionsNotViewedCount(state, value) {
            state.historySessionsNotViewedCount = value;
        },
        /**
         * @param {*} state
         * @param {Array} payload
         */
        setCopyQuestions(state, payload) {
            state.copiedQuestions = cloneDeep(payload);
        },
        /**
         * @param {*} state
         * @param {Array} payload
         */
        setCopyIncorrectAnswers(state, payload) {
            state.copiedIncorrectAnswers = cloneDeep(payload);
        },
        clearCopyQuestions(state) {
            state.copiedQuestions = null;
        },
        clearCopyIncorrectAnswers(state) {
            state.copiedIncorrectAnswers = null;
        },
        /**
         * @param {*} state
         * @param {{ sessionId, messageId }} payload
         */
        setViewedMessage(state, payload) {
            const {
                messageId,
                sessionId,
            } = payload;

            if (!state.session || state.session?.id !== sessionId) return;

            const messageIndexById = state.messages.list.findIndex(item => item.id === messageId);

            if (messageIndexById >= 0) {
                const messagesNotViewed = state.messages.list.slice(0, messageIndexById);
                const messageViewed = messagesNotViewed.map(item => ({
                    ...item,
                    is_viewed: true,
                }));

                state.messages.list = state.messages.list.toSpliced(0, messagesNotViewed.length, messageViewed);
            }
        },
        setIsError(state, value) {
            state.isError = value;
        },
        setMessageCanceled(state, messageId) {
            state.messages.list = state.messages.list.map(msg => {
                if (msg.id !== messageId) return msg;

                return {
                    ...msg,
                    status: 'canceled',
                };
            });
        },

        /**
         * @param state
         * @param {{sessionId,messageId}} payload
         */
        setViewedHistoryItem(state, {
            sessionId,
            messageId,
        }) {
            /**
             * @param {Array} list
             * @return {*}
             */
            const getSessionListWithViewdMsg = list => list.map(session => {
                if (session.id === sessionId && session.last_message?.id === messageId) {
                    return getNewBlockInstance(session, true, '/last_message/is_viewed');
                }

                return session;
            });

            state.historySessionsList = getSessionListWithViewdMsg(state.historySessionsList);
            state.newHistorySessionsList = getSessionListWithViewdMsg(state.newHistorySessionsList);
        },

        setMessageStatus(state, { sessionId, messageId, status }) {
            if (!state.session || state.session?.id !== sessionId) return;

            const messageIndex = state.messages.list.findIndex(msg => msg.id === messageId);

            if (messageIndex > -1) {
                state.messages.list = state.messages.list.toSpliced(messageIndex, 1, {
                    ...state.messages.list[messageIndex],
                    status,
                });
            }
        },

        removeMessage(state, { sessionId, messageId }) {
            if (!state.session || state.session.id !== sessionId) return;

            state.messages.list = state.messages.list.filter(msg => msg.id !== messageId);
        },

        setHistoryMessageFailed(state, { sessionId, messageId }) {
            /**
             * @param {Array} list
             * @return {*}
             */
            const getSessionListWithFailedMsg = list => list.map(session => {
                if (session.id === sessionId && session.last_message?.id === messageId) {
                    return getNewBlockInstance(session, AiMessageStatus.FAILED, '/last_message/status');
                }

                return session;
            });

            state.historySessionsList = getSessionListWithFailedMsg(state.historySessionsList);
            state.newHistorySessionsList = getSessionListWithFailedMsg(state.newHistorySessionsList);
        },
    },
    actions: {
        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {number|string} payload.blockId
         * @param {string} payload.promptType
         * @param {string} [payload.text]
         * @param {string} [payload.sessionType]
         * @param {string} [payload.questionId]
         * @param {object} [payload.additionalParams]
         */
        async showModalAiSession({ dispatch, getters, commit }, payload) {
            try {
                commit('setAiSession', null);
                commit('clearMessages');
                commit('setIsError', false);
                commit('setShowModal', true);

                const { text } = payload;

                await dispatch('createAiSession', payload);

                const { id: sessionId } = getters.aiSession;

                await dispatch('loadAiMessages', { sessionId });
                await dispatch('sendAiMessage', {
                    sessionId,
                    text,
                });
                dispatch('startAiSocketConnection', sessionId);
            } catch (e) {
                console.error(e);
                commit('setIsError', true);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {number|string} payload.sessionId
         */
        async showModalAiBySessionId({ dispatch, commit }, payload) {
            try {
                const { sessionId } = payload;

                commit('setAiSession', null);
                commit('clearMessages');
                commit('setIsError', false);
                commit('setShowModal', true);

                await Promise.any([
                    dispatch('loadAiSession', sessionId),
                    dispatch('loadAiMessages', { sessionId }),
                ]);
                dispatch('startAiSocketConnection', sessionId);
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} [payload]
         * @param {object} [payload.params]
         * @returns {Promise<void>}
         */
        async loadAiMessages({ commit }, payload = {}) {
            try {
                commit('setLoadingMessages', true);

                const { sessionId, params } = payload;

                const { data: respData } = await api.getAiMessages(sessionId, params);

                commit('addMessages', {
                    messages: respData.data,
                    meta: respData.meta,
                });
            } finally {
                commit('setLoadingMessages', false);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @returns {Promise<void>}
         */
        async loadAiNextMessages({ getters, dispatch }) {
            if (!getters.messagesNextCursor || !getters.aiSession || getters.messages?.loading) return;

            await dispatch('loadAiMessages', {
                sessionId: getters.aiSession.id,
                params: { cursor: getters.messagesNextCursor },
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {string} payload.sessionId
         * @param {string} payload.text
         * @returns {Promise<void>}
         */
        async sendAiMessage({ commit }, payload) {
            try {
                commit('setIsSendingMessage', true);

                const { sessionId, text = '' } = payload;

                if (!sessionId) return;

                const { data: respData } = await api.sendAiMessage(sessionId, { text });

                commit('pushMessage', {
                    sessionId,
                    message: respData.data,
                });
            } finally {
                commit('setIsSendingMessage', false);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         */
        clearCopyQuestions({ commit }) {
            commit('clearCopyQuestions');
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         */
        clearCopyIncorrectAnswers({ commit }) {
            commit('clearCopyIncorrectAnswers');
        },

        /**
         * @param @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {number|string} payload.blockId
         * @param {string} payload.promptType
         * @param {string} [payload.sessionType]
         * @param {string} [payload.questionId]
         * @param {object} [payload.additionalParams]
         */
        async createAiSession({ commit }, payload) {
            const { blockId, promptType, sessionType, questionId, additionalParams } = payload;
            const { data: responseData } = await api.createAiSession({
                activityId: blockId,
                promptType,
                type: sessionType,
                params: {
                    ...additionalParams,
                    questionId,
                },
            });
            const aiSession = responseData.data;

            commit('setAiSession', aiSession);
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param sessionId
         * @returns {Promise<void>}
         */
        async loadAiSession({ commit }, sessionId) {
            try {
                const { data } = await api.getAiSession(sessionId);

                commit('setAiSession', data.data);
            } catch (err) {
                console.error(err);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {object} payload
         * @param {number} payload.sessionId
         * @param {number} payload.messageId
         */
        async sendViewMessage({ commit }, payload) {
            try {
                const { sessionId, messageId } = payload;

                await api.setViewedAiMessage({
                    sessionId,
                    messageId,
                });

                commit('setViewedHistoryItem', payload);

                // commit('setViewedMessage', {
                //     sessionId,
                //     messageId,
                // });
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {{ data: {
         *     message_id: number,
         *     session_id: number,
         *     message: Object,
         * } }} payload
         */
        processAiSessionSocketData({ commit }, payload) {
            const { data: { session_id, message } } = payload;

            commit('pushMessage', {
                sessionId: session_id,
                message,
            });
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param sessionId
         */
        startAiSocketConnection({ commit, dispatch }, sessionId) {
            const socketInstance = getEchoInstance()
                .private(`ai.session.${sessionId}`)
                .stopListening('.ai.session')
                .listen(
                    '.ai.session',
                    /**
                     *
                     * @param {{
                     *     message_id: number,
                     *     session_id: number,
                     *     message: Object,
                     * }} data
                     */
                    data => {
                        dispatch('processAiSessionSocketData', { data });
                    },
                )
                .stopListening('.ai.session_failed')
                .listen('.ai.session_failed', ({ session_id, message_id, response }) => {
                    console.error('ошибка отправления собщения в AI', response.error);

                    commit('setMessageStatus', {
                        sessionId: session_id,
                        messageId: message_id,
                        status: AiMessageStatus.FAILED,
                    });

                    createSnackbar({
                        type: 'error',
                        message: 'Ошибка. Попробуйте еще раз',
                    });
                });

            commit('setSocketInstance', socketInstance);
        },
        /**
         * @param {import('vuex').ActionContext} ctx
         */
        stopAiSocketConnection({ commit, state }) {
            const { socketInstance } = state;

            if (!socketInstance) return;

            socketInstance.stopListening('.ai.session').stopListening('.ai.session_failed');

            commit('setSocketInstance', null);
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {{ sessionId: string|number, messageId: string|number }} data
         * @return {Promise<void>}
         */
        async cancelSearchResult({ commit }, data) {
            try {
                await api.cancelMessage(data);
                commit('setMessageCanceled', data.messageId);
            } catch (error) {
                console.error(error);
            }
        },

        async getAiHistorySessions({ getters, commit }, { developmentCenterId, daysPeriod = 30, page }) {
            try {
                commit('setHistorySessionsLoading', true);

                const { data } = await api.getAiHistorySessions({
                    developmentCenterId,
                    daysPeriod,
                    page,
                });

                const historyList = page === 1
                    ? data.data
                    : [...getters.historySessionsList, ...data.data];

                commit('setHistorySessionsList', historyList);
                commit('setHistorySessionsLastPage', data.meta.last_page);
                commit('setCurrentHistorySessionsPage', data.meta.current_page);
                commit('setHistorySessionsNotViewedCount', data.not_viewed_count);
            } catch (error) {
                console.error(error);
            } finally {
                commit('setHistorySessionsLoading', false);
            }
        },
        resetHistorySessionsData({ commit }) {
            commit('setHistorySessionsList', []);
            commit('setHistorySessionsLastPage', 1);
            commit('setCurrentHistorySessionsPage', 1);
            commit('setHistorySessionsNotViewedCount', 0);
        },
        async getAiNewHistorySessions({ commit }, { developmentCenterId, daysPeriod = 30, page = 1 }) {
            try {
                const { data } = await api.getAiHistorySessions({
                    developmentCenterId,
                    daysPeriod,
                    page,
                });

                commit('setNewHistorySessionsList', data.data.slice(0, MAX_DISPLAYED_HISTORY_ITEMS_COUNT));
                commit('setHistorySessionsNotViewedCount', data.not_viewed_count);
            } catch (error) {
                console.error(error);
            }
        },

        /**
         * @param {import('vuex').ActionContext} ctx
         * @param {{ sessionId, messageId }} payload
         * @return {Promise<void>}
         */
        async setHistorySessionsViewedMessage({ dispatch }, payload) {
            await dispatch('sendViewMessage', payload);
        },

        initAiManageUsersSocketConnection({ getters, commit, dispatch }, { userId, developmentCenterId }) {
            getEchoInstance()
                .private(`ai.manage.users.${userId}.development-centers.${developmentCenterId}`)
                .stopListening('.ai.session')
                .listen('.ai.session', data => {
                    const { session_id: sessionId, message } = data;

                    dispatch('getAiNewHistorySessions', { developmentCenterId });

                    const updatedHistoryList = getters.historySessionsList.map(item => (item.id === sessionId
                        ? {
                            ...item,
                            last_message: message,
                        }
                        : item));

                    commit('setHistorySessionsList', updatedHistoryList);
                })
                .stopListening('.ai.session_failed')
                .listen('.ai.session_failed', ({ session_id, message_id, response }) => {
                    console.error(response);
                    commit('setHistoryMessageFailed', {
                        sessionId: session_id,
                        messageId: message_id,
                    });
                });
        },
        stopAiManageUsersSocketConnection(_, { userId, developmentCenterId }) {
            getEchoInstance().leave(`ai.manage.users.${userId}.development-centers.${developmentCenterId}`);
        },

        async retryGenerateMessage({ getters, commit }, { sessionId, messageId }) {
            await api.retryAiMessage({
                sessionId,
                messageId,
            });

            const removeMsg = getters.messagesList.find(msg => msg.id === messageId);

            if (!removeMsg) return;

            commit('removeMessage', {
                sessionId,
                messageId,
            });

            const lastMessageAfterRemove = getters.messagesList?.at(-1);

            if (lastMessageAfterRemove) {
                commit('setMessageStatus', {
                    sessionId,
                    messageId: lastMessageAfterRemove.id,
                    status: AiMessageStatus.PROCESSING,
                });
            }
        },
    },
};
