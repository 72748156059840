<template>
    <div
        v-if="typing"
        ref="typing"
        class="AiModalResult"></div>
    <div
        v-else
        v-html="result"
        class="AiModalResult"></div>
</template>

<script>
/* eslint-disable vue/no-unused-properties */

import { Utils } from '@sbs/uikit-constructor';
import Typed from 'typed.js';
import { delay } from '@/utils/utils.js';

export default {
    name: 'AiModalResult',
    emits: [
        'start',
        'complete',
        'typed',
        'resume',
        'reset',
        'stop',
    ],
    data() {
        return {
            typed: null,
            resizeObserver: null,
            timeStartHidden: false,
        };
    },
    methods: {
        handlePreStringTyped(...args) {
            this.$emit('typed', ...args);
        },
        handleTypingResumed(...args) {
            this.$emit('resume', ...args);
        },
        handleReset(...args) {
            this.$emit('reset', ...args);
        },
        handleStart(...args) {
            this.$emit('start', ...args);
        },
        handleStop(...args) {
            this.$emit('stop', ...args);
        },
        handleComeplete(...args) {
            this.$emit('complete', ...args);
        },
        getElement() { return this.$refs.typing; },

        getContainerHeight() {
            this.getElement()?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        },

        initTyped() {
            const element = this.getElement();

            if (!element || !this.typing) return;

            this.typed = new Typed(element, {
                startDelay: 0,
                typeSpeed: 0,
                loop: false,
                showCursor: false,
                strings: [this.result],
                preStringTyped: this.handlePreStringTyped,
                onTypingResumed: this.handleTypingResumed,
                onReset: this.handleReset,
                onStart: this.handleStart,
                onStop: this.handleStop,
                onComplete: this.handleComeplete,
            });
            this.handleStart();
            document.addEventListener('visibilitychange', this.handleVisibleChange);
        },

        async handleVisibleChange() {
            if (document.hidden) {
                this.timeStartHidden = Date.now();
            } else if (this.typed) {
                await delay(100);
                this.typed.stop();
                await delay(100);
                this.typed.pause.curStrPos = this.typed.pause.curString.length - 1;
                this.typed.start();
            }
        },

        initResizeObserver() {
            const element = this.getElement();

            if (!element) return;

            this.resizeObserver = new Utils.ResizeObserver(this.getContainerHeight);
            this.resizeObserver.observe(element);
        },

        stop() {
            this.typed?.stop();
        },

        start() {
            this.typed?.start();
        },
    },
    props: {
        result: {
            type: String,
            default: null,
        },
        typing: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.initTyped();
        this.initResizeObserver();
    },
    beforeUnmount() {
        this.resizeObserver?.disconnect?.();
        this.typed?.destroy?.();
        document.addEventListener('visibilitychange', this.handleVisibleChange);
    },
};
</script>

<style lang="scss">
/* stylelint-disable selector-max-compound-selectors, declaration-no-important */
.AiModalResult {
    p {
        @include typo-secondary-book;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    // Списки
    ul,
    ol {
        padding: 0;
        list-style-type: none;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        > li {
            display: table;

            @include typo-secondary-book;

            &::marker {
                display: none;
            }

            &::before {
                display: table-cell;
                min-width: 24px;
                color: $primary;
                white-space: nowrap;

                @include typo-secondary-medium;
            }

            > ul,
            > ol {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                > li {
                    @include typo-secondary-book;

                    &::before {
                        @include typo-secondary-medium;
                    }
                }
            }

            p {
                margin-bottom: 0 !important;
            }
        }
    }

    ol {
        counter-reset: item calc(var(--start-value) - 1);

        > li {
            counter-increment: item;

            &::before {
                content: counters(item, ".") ".";
            }

            > ol {
                counter-reset: item;
            }
        }
    }

    ul {
        > li {
            &::before {
                text-align: center;
                content: "\2022";
            }
        }
    }

    // Таблицы
    table {
        display: block;
        width: 100%;
        max-width: 410px;
        overflow-x: auto;
        border: none;
        border-collapse: separate;
        border-spacing: 0;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        thead th {
            color: $gray-deep;
            font-weight: 500;

            &:first-child {
                border-top-left-radius: 15px;
            }

            &:last-child {
                border-top-right-radius: 15px;
            }
        }

        tr {
            &:first-child {
                th,
                td {
                    border-top: 1px solid $gray-light;
                }

                td {
                    &:first-child {
                        border-top-left-radius: 15px;
                    }

                    &:last-child {
                        border-top-right-radius: 15px;
                    }
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 15px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 15px;
                    }
                }
            }

            &:nth-child(odd) {
                background-color: $gray-ultra-light;
            }
        }

        td,
        th {
            position: relative;
            min-width: 150px;
            padding: 10px 15px;
            text-overflow: ellipsis;
            vertical-align: top;
            border-right: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;

            &:first-child {
                border-left: 1px solid $gray-light;
            }

            > p {
                margin-bottom: 0 !important;
            }
        }
    }

    h1 {
        @include typo-h1-bold;
    }

    h2 {
        @include typo-h2-bold;
    }

    h3 {
        @include typo-h3-bold;
    }

    h4 {
        @include typo-h4-bold;
    }

    img {
        width: 100%;
    }
}
</style>
