<template>
    <div class="AiMessageUserWrap">
        <div class="AiMessageUser">
            <TextBlock kind="Desktop/font/body/s1-book">
                {{ messageText }}
            </TextBlock>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import TextBlock from '@/components/UI/TextBlock.vue';

export default defineComponent({
    name: 'AiMessageUser',
    components: {
        TextBlock,
    },
    emits: ['typing:start', 'typing:complete'],
    computed: {
        messageText() {
            return this.message.text;
        },
    },
    props: {
        /**
         * @type {import('vue').PropType<{
         *       id: number,
         *       prompt_type: 'improve_writing' | 'correct_writing' | 'make_shorter' | 'make_longer' | 'summarize' | 'generate_image' | 'generate_incorrect_answers' | 'make_question' | 'generate_consolidate_material_task' | 'generate_testing_skills_task' | 'generate_reflection_on_material_task' | 'generate_test',
         *       author: 'user' | 'assistant' | 'system',
         *       text: string,
         *       status: 'created' | 'processing' | 'processed' | 'canceled' | 'failed',
         *       updated_at: string,
         *       is_viewed: boolean,
         *     }>}
         */
        message: {
            type: Object,
            required: true,
        },
        // eslint-disable-next-line vue/no-unused-properties
        isLast: Boolean,
    },
});
</script>

<style lang="scss" scoped>
.AiMessageUserWrap {
    display: flex;
    justify-content: flex-end;
    padding-left: 25px;
}

.AiMessageUser {
    min-width: 0;
    padding: 15px 20px;
    background: #ebedf1;
    border: 1px solid #d6d9df;
    border-radius: 15px 5px 15px 15px;
}
</style>
