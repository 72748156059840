<template>
    <div class="DefaultLayout">
        <router-view class="DefaultLayout__View" />
        <LongreadUpdatedViewPlayer />
        <CookiesPopup />
        <AiHistoryPopup />
        <AiModal />
    </div>
</template>

<script>
import AiHistoryPopup from '@/admin/components/AI/AiHistory/AiHistoryPopup/AiHistoryPopup.vue';
import LongreadUpdatedViewPlayer from '@/admin/components/Longread/LongreadUpdatedViewPlayer.vue';
import CookiesPopup from '@/components/CookiesPopup/CookiesPopup.vue';
import AiModal from '@/admin/components/AI/AiModal/AiModal.vue';

export default {
    name: 'DefaultLayout',
    components: {
        LongreadUpdatedViewPlayer,
        CookiesPopup,
        AiHistoryPopup,
        AiModal,
    },
};
</script>

<style lang="scss">
.DefaultLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;
    // min-height: calc(100vh * var(--zoom));

    &__View {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
</style>
