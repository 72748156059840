<template>
    <SbsModal
        v-model="isShowAiModal"
        class="AiModal"
        modalBodyClass="AiModal__ModalBody"
        modalContentClass="AiModal__ModalContent"
        modalContainerClass="AiModal__ModalContainer"
        height="100%"
        position="right"
        :showOverlay="false"
        :bodyScrollLock="false"
        width="450">
        <template #contentBefore>
            <SbsButtonIcon
                class="AiModal__HistoryButton"
                :expandClickArea="false"
                @click="handleClickBackToHistory">
                <InlineSvg :src="require('@/assets/svg/icons-backward-arrow.svg')" />
            </SbsButtonIcon>
        </template>
        <div class="AiModal__ContentWrapper">
            <div class="AiModal__Header">
                <InlineSvg
                    class="AiModal__HeaderIcon"
                    :src="require('@/assets/svg/ic_ai_stars.svg')" />
                <div class="AiModal__HeaderColumn">
                    <div class="AiModal__HeaderTitle">
                        Ассистент ИИ
                    </div>
                    <AiModalLocationPopup
                        v-if="aiSession?.location"
                        v-model="isShowLocationPopup">
                        <SbsLink
                            class="AiModal__HeaderLocation"
                            colorTheme="black"
                            hoverUnderline="primary"
                            @click="isShowLocationPopup = !isShowLocationPopup">
                            Расположение блока
                        </SbsLink>
                    </AiModalLocationPopup>
                </div>
            </div>
            <ClimbUpButton
                v-if="isShowClimbUpBtn"
                class="AiModal__ClimbUp"
                @click="handleClickScrollTop" />
            <div
                v-if="isError"
                class="AiModal__ErrorContainer">
                Ошибка инициализации ИИ
            </div>
            <template v-else>
                <SbsScrollWrapper
                    ref="scrollRef"
                    class="AiModal__Content"
                    contentClass="AiModal__ContentScroll"
                    hideTopCurtain
                    hideBottomCurtain
                    @scroll="checkShowClimbUpBtn"
                    @scroll:reachStart="handleScrollReachStart">
                    <AiModalSpinner v-if="isShowLoadingMessages" />
                    <AiModalResultStub v-if="isEmptyMessages" />
                    <template v-else-if="!isLoadingSession">
                        <AiMessage
                            v-for="message in messagesList"
                            :key="message.id"
                            :message="message"
                            :isLast="isLastMessage(message)"
                            @retry="handleRetry"
                            @typing:string="handleTypingStart"
                            @typing:complete="handleTypingComplete"
                            @like="handleLikeMessage"
                            @dislike="handleDislikeMessage"
                            @copy="handleCopyMessage" />
                    </template>
                    <AiGeneratingInfo v-if="isGeneratingResponse" />
                    <InfoCard
                        v-if="isMessageError"
                        text="Ошибка генерации"
                        :hasClose="false" />
                    <div class="AiModal__ScrollSpacer"></div>
                </SbsScrollWrapper>
                <AiQueryTextarea
                    v-model="query"
                    :isPaused="isGeneratingResponse"
                    :disabled="isDisabledTextInput"
                    placeholder="Что написать или улучшить?"
                    @confirm="handleSendMessage"
                    @cancel="handleCancel" />
                <div class="AiModal__ContentWarning">
                    В ответах нейросети могут быть ошибки
                </div>
            </template>
        </div>
    </SbsModal>
    <AiSupportModal
        v-model="isShowSupportModal"
        :submitting="isDisliking"
        @submit="submitDislikeAnswer" />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import { useClipboardItems } from '@vueuse/core';
import { ref } from 'vue';
import useSnackbar from '@/hooks/snackbars.js';
import { getHtmlText } from '@/utils/utils.js';
import { setLikeAiMessage, setDislikeAiMessage } from '@/api/AiApi.js';
import {
    getCopyItemsForQuestions,
    getResultMessage,
    isMessageProcessing,
} from '@/admin/components/AI/AiModal/helpers.js';
import { useScrollSaver } from '@/hooks/useScrollSaver.js';
import { AiMessagesPromptTypes, AiMessageStatus } from '@/constants/AI.js';
import ClimbUpButton from '@/components/UI/ClimbUpButton.vue';
import AiSupportModal from '@/admin/components/AI/AiSupportModal/AiSupportModal.vue';
import AiModalLocationPopup from '@/admin/components/AI/AiModal/AiModalLocationPopup.vue';
import AiMessage from '@/admin/components/AI/AiModal/AiMessage.vue';
import AiGeneratingInfo from '@/admin/components/AI/AiModal/AiGeneratingInfo.vue';
import InfoCard from '@/components/UI/InfoCard.vue';
import AiQueryTextarea from '../AiQueryTextarea.vue';
import AiModalSpinner from './AiModalSpinner.vue';
import AiModalResultStub from './AiModalResultStub.vue';

const VISIBLE_SCROLL_OFFSET = 60;

const PROMPT_TYPE_DISALLOW_SEND_TEXT = [
    AiMessagesPromptTypes.GENERATE_TEST,
    AiMessagesPromptTypes.GENERATE_TESTING_SKILLS_TASK,
    AiMessagesPromptTypes.GENERATE_CONSOLIDATE_MATERIAL_TASK,
];

export default {
    name: 'AiModal',
    components: {
        InfoCard,
        AiGeneratingInfo,
        AiMessage,
        AiModalLocationPopup,
        InlineSvg,
        AiQueryTextarea,
        AiModalSpinner,
        ClimbUpButton,
        AiModalResultStub,
        AiSupportModal,
    },
    data() {
        return {
            query: '',
            dislikeMesage: null,
            dislikeMesageCallback: null,
            isShowSupportModal: false,
            isShowLocationPopup: false,
            isShowClimbUpBtn: false,
            isTypingResult: false,
            isFirstScroll: true,
            isCanceling: false,
            isRetrying: false,
            isDisliking: false,
        };
    },
    computed: {
        ...mapState('AI', ['isError', 'isSendingMessage']),
        ...mapGetters('AI', [
            'isShowModal',
            'aiSession',
            'messages',
            'messagesNextCursor',
        ]),
        messagesList() {
            return this.messages.list ?? [];
        },
        lastMessage() {
            return this.messagesList.at(-1);
        },
        isShowAiModal: {
            get() {
                return this.isShowModal;
            },
            set(value) {
                this.setShowModal(value);
            },
        },
        isGeneratingResponse() {
            return isMessageProcessing(this.lastMessage);
        },
        isMessageError() {
            return this.lastMessage?.status === AiMessageStatus.FAILED;
        },
        isEmptyMessages() { return !this.isLoadingMessages && !this.isSendingMessage && !this.messagesList.length; },
        isLoadingSession() { return !this.aiSession; },
        isFetchingMessages() { return this.messages.loading; },
        isLoadingMessages() { return !this.messagesList.length && this.isFetchingMessages; },
        isDisabledTextInput() {
            return (
                this.isTypingResult ||
                this.isLoadingSession ||
                this.isLoadingMessages ||
                PROMPT_TYPE_DISALLOW_SEND_TEXT.includes(this.aiSession?.prompt_type)
            );
        },
        isHaveNextMessages() {
            return Boolean(this.messagesNextCursor);
        },
        isShowLoadingMessages() {
            return this.isLoadingMessages || this.isHaveNextMessages || this.isLoadingSession;
        },
        lastData() { return [this.aiSession, this.lastMessage]; },
        developmentCenterId() {
            return this.$route.params.developmentCenterId;
        },
    },
    methods: {
        ...mapActions('AI', [
            'sendAiMessage',
            'sendViewMessage',
            'stopAiSocketConnection',
            'loadAiNextMessages',
            'cancelSearchResult',
            'retryGenerateMessage',
        ]),
        ...mapMutations('AI', ['setCopyQuestions', 'setCopyIncorrectAnswers', 'setShowModal', 'setShowModalHistory']),
        handleClickBackToHistory() {
            this.isShowAiModal = false;
            this.setShowModalHistory(true);
        },
        handleSendMessage() {
            this.sendAiMessage({
                sessionId: this.aiSession.id,
                text: this.query,
            });
            this.query = '';
        },
        async handleCancel() {
            if (this.isCanceling || !this.isGeneratingResponse) return;

            try {
                this.isCanceling = true;
                await this.cancelSearchResult({
                    sessionId: this.aiSession.id,
                    messageId: this.lastMessage.id,
                });
                this.isTypingResult = false;
            } finally {
                this.isCanceling = false;
            }
        },
        async handleLikeMessage(message) {
            try {
                await setLikeAiMessage({
                    sessionId: this.aiSession.id,
                    messageId: message.id,
                });

                this.createSnackbar({
                    message: 'Спасибо за оценку',
                    type: 'success',
                });
            } catch {
                this.createSnackbar({
                    message: 'Ошибка. Попробуйте еще раз',
                    type: 'error',
                });
            }
        },
        handleDislikeMessage({ message, callback }) {
            this.dislikeMesage = message;
            this.dislikeMesageCallback = callback;
            this.isShowSupportModal = true;
        },
        getLinkForDislike() {
            try {
                const locations = this.aiSession?.location;

                const { fullPath } = this.$router.resolve({
                    name: 'AdminLongreadUpdateView',
                    hash: locations?.activity?.id ? `#activity-${locations?.activity?.id}` : null,
                    params: {
                        developmentCenterId: locations?.development_center?.id,
                        solutionId: locations?.solution?.id,
                        versionId: locations?.version?.id,
                        longreadId: locations?.page?.id,
                    },
                });

                return `${window.location.origin}${fullPath}`;
            } catch {
                return '';
            }
        },
        async submitDislikeAnswer({ message, problems, dialogThemes }) {
            try {
                this.isDisliking = true;
                await setDislikeAiMessage({
                    sessionId: this.aiSession.id,
                    messageId: this.dislikeMesage.id,
                    data: {
                        comment: message,
                        reason_id: problems[0],
                        theme_id: dialogThemes[0],
                        link: this.getLinkForDislike(),
                    },
                });

                this.dislikeMesageCallback();

                this.createSnackbar({
                    message: 'Спасибо за оценку и обратную связь',
                    type: 'success',
                });

                this.isShowSupportModal = false;
            } catch (e) {
                console.error(e);
                this.createSnackbar({
                    message: 'Ошибка. Попробуйте еще раз',
                    type: 'error',
                });
            } finally {
                this.isDisliking = false;
            }
        },
        async handleRetry(message) {
            try {
                if (this.isRetrying) return;

                this.isRetrying = true;
                await this.retryGenerateMessage({
                    sessionId: this.aiSession?.id,
                    messageId: message.id,
                });
            } catch (e) {
                console.error(e);
                this.createSnackbar({
                    type: 'error',
                    message: 'Ошибка перегенрации ответа. Попробуйте еще раз',
                });
            } finally {
                this.isRetrying = false;
            }
        },
        checkShowClimbUpBtn(event) {
            if (!event) return;

            this.isShowClimbUpBtn = event.target?.scrollTop > VISIBLE_SCROLL_OFFSET;
            this.scrollSaver.handleScroll(event);
        },
        handleClickScrollTop() {
            this.scrollTop();
        },
        osInstance() {
            return this.$refs?.scrollRef?.osInstance?.();
        },

        scrollTop(behavior = 'smooth') {
            this.$refs?.scrollRef?.scroll({
                top: 0,
                behavior,
            });
        },

        scrollBottom(behavior = 'smooth') {
            const os = this.osInstance();

            if (os) {
                this.$refs?.scrollRef?.scroll({
                    top: os.elements().viewport.scrollHeight,
                    behavior,
                });
            }
        },

        handleTypingStart() {
            this.isTypingResult = true;
        },

        async handleTypingComplete() {
            this.isTypingResult = false;

            await this.$nextTick();

            this.scrollBottom();
        },
        getCopyData(message) {
            if (message.test_questions?.length > 0) {
                return getCopyItemsForQuestions(message.test_questions);
            }

            if (message.incorrect_answers?.length > 0) {
                const textAnswers = message.incorrect_answers.join('\n');
                const textAnswersHtml = `<ul><li>${message.incorrect_answers.join('</li><li>')}</li></ul>`;

                return new ClipboardItem({
                    'text/plain': new Blob([textAnswers], { type: 'text/plain' }),
                    'text/html': new Blob([textAnswersHtml], { type: 'text/html' }),
                });
            }

            const result = getResultMessage(message);
            const textPlain = getHtmlText(result);

            return new ClipboardItem({
                'text/plain': new Blob([textPlain], { type: 'text/plain' }),
                'text/html': new Blob([result], { type: 'text/html' }),
            });
        },
        async handleCopyMessage(message) {
            try {
                await this.clipboard.copy([this.getCopyData(message)]);

                if (message.test_questions?.length > 0) {
                    this.setCopyQuestions(message.test_questions);
                }

                if (message.incorrect_answers?.length > 0) {
                    this.setCopyIncorrectAnswers(message.incorrect_answers);
                }

                this.createSnackbar({
                    message: 'Скопировано в буфер обмена',
                    type: 'success',
                });
            } catch {
                this.createSnackbar({
                    message: 'Ошибка копирования',
                    type: 'error',
                });
            }
        },
        isLastMessage(message) {
            return message.id === this.lastMessage.id;
        },
        async handleScrollReachStart() {
            if (this.isFetchingMessages) return;

            await this.scrollSaver.scrollSave();
            await this.loadAiNextMessages();
            await this.scrollSaver.scrollRestore();
        },
    },
    watch: {
        lastData: {
            async handler() {
                if (!this.isShowAiModal) return;

                if (this.lastMessage && this.aiSession) {
                    if (!this.lastMessage.is_viewed) {
                        this.sendViewMessage({
                            sessionId: this.aiSession.id,
                            messageId: this.lastMessage.id,
                        });
                    }

                    await this.$nextTick();
                    this.scrollBottom(this.isFirstScroll ? 'instant' : 'smooth');
                    this.isFirstScroll = false;
                }
            },
        },
        developmentCenterId() {
            this.isShowAiModal = false;
        },
        isShowAiModal(val) {
            this.isShowClimbUpBtn = false;

            if (!val) {
                this.stopAiSocketConnection();
                this.isFirstScroll = true;
            }
        },
    },
    beforeUnmount() {
        this.stopAiSocketConnection();
    },
    setup() {
        const scrollRef = ref(null);
        const { createSnackbar } = useSnackbar();
        const clipboard = useClipboardItems();
        const scrollSaver = useScrollSaver(scrollRef);

        return {
            scrollRef,
            createSnackbar,
            clipboard,
            scrollSaver,
        };
    },
};
</script>

<style lang="scss">
.AiModal.sbs-modal.opened {
    pointer-events: unset;
}

.AiModal__ModalBody {
    padding: 25px;
    padding-top: 20px;
    border-radius: 15px;
}

.AiModal__ModalContainer {
    width: 100%;
}

.AiModal__ClimbUp {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
}

.AiModal__ModalContent {
    margin: 16px;
    overflow: visible;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
    pointer-events: auto;

    .sbs-modal__close-button i {
        font-size: 25px;
    }
}

.AiModal__Header {
    display: flex;
    gap: 5.5px;
    padding-bottom: 16px;
}

.AiModal__HeaderColumn {
    display: flex;
    flex-direction: column;
}

.AiModal__HeaderTitle {
    @include typo-primary-medium;
}

.AiModal__HeaderIcon {
    margin-top: 3px;
}

.AiModal__HistoryButton {
    position: absolute;
    top: 45px;
    left: -30px;
    z-index: 1;
    padding: 15px;
    background-color: white;
    border-radius: $rounded-circle;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);

    &:not(.disabled):active {
        background-color: $gray-ultra-light;
        opacity: 1;
    }
}

.AiModal__Content {
    flex-grow: 1;
    height: 100%;

    & > .sbs-scroll-wrapper__scroll-wrapper {
        flex-grow: 1;
    }
}

.AiModal__ContentScroll {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
}

.AiModal__ScrollSpacer {
    height: 1px;
}

.AiModal__ContentWarning {
    margin-top: 10px;
    color: $gray-deep;
    text-align: center;

    @include typo-subsecondary-book;
}

.AiModal__ContentWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 77px);
}

.AiModal__ErrorContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.AiModal__ContentList {
    display: flex;
    flex-direction: column-reverse;
}
</style>
