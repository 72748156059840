<template>
    <div class="AiQuestions">
        <TextBlock
            class="AiQuestions__Title"
            kind="Desktop/font/body/s1-demi">
            Можно добавлять вопросы по одному или все сразу
        </TextBlock>
        <div class="AiQuestions__List">
            <AiQuestionResolver
                v-for="(question, ind) in questions"
                :key="ind"
                :question="question" />
        </div>
    </div>
</template>

<script>
import TextBlock from '@/components/UI/TextBlock.vue';
import AiQuestionResolver from '@/admin/components/AI/AiModal/AiQuestionResolver.vue';

export default {
    name: 'AiQuestions',
    components: {
        AiQuestionResolver,
        TextBlock,
    },
    props: {
        questions: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.AiQuestions__Title {
    margin-bottom: 15px;
}

.AiQuestions__List {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
</style>
