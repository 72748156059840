<template>
    <SbsPopup
        v-model="value"
        popupClass="AiModalLocationPopup"
        popupContentClass="AiModalLocationPopup__Content"
        :showCloseButton="false">
        <slot></slot>
        <template #content>
            <div class="AiModalLocationPopup__Header">
                <div class="AiModalLocationPopup__Title">
                    Расположение блока
                </div>
                <SbsButtonIcon
                    width="15"
                    height="15"
                    iconName="close"
                    colorTheme="gray"
                    hoverColor="black"
                    @click="value = false" />
            </div>
            <div class="AiModalLocationPopup__Layout">
                <template
                    v-for="row in navRows"
                    :key="row.title">
                    <div class="AiModalLocationPopup__LayoutTitle">
                        {{ row.title }}:
                    </div>
                    <SbsTooltip
                        :disabled="!row.data.is_deleted"
                        :content="row.deletedText">
                        <SbsLink
                            v-bind="linkData"
                            :to="row.router"
                            :text="row.data.name"
                            :disabled="row.data.is_deleted"
                            class="AiModalLocationPopup__LayoutLink" />
                    </SbsTooltip>
                </template>
            </div>
            <SbsTooltip
                :disabled="!aiSession.location?.activity?.is_deleted"
                content="Блок удален">
                <SbsLink
                    v-bind="blockLinkData"
                    :to="blockRouter"
                    :disabled="aiSession.location?.activity?.is_deleted"
                    class="AiModalLocationPopup__BlockLink">
                    <div class="AiModalLocationPopup__BlockLink">
                        Перейти к блоку
                        <InlineSvg :src="require('@/assets/svg/icons-arrow-right.svg')" />
                    </div>
                </SbsLink>
            </SbsTooltip>
        </template>
    </SbsPopup>
</template>

<script>
import { mapGetters } from 'vuex';
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiModalLocationPopup',
    emits: ['update:modelValue'],
    components: { InlineSvg },
    computed: {
        ...mapGetters('AI', ['aiSession']),
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        linkData() {
            return {
                colorTheme: 'black',
                hoverUnderline: 'primary',
            };
        },
        blockLinkData() {
            return {
                colorTheme: 'primary',
                underlined: false,
            };
        },
        navRows() {
            if (!this.aiSession?.location) return [];

            const {
                solution,
                version,
                page,
            } = this.aiSession.location;

            const rows = [];

            if (solution) {
                rows.push({
                    title: 'Проект',
                    data: this.aiSession.location.solution,
                    router: this.solutionRouter,
                    deletedText: 'Проект удален',
                });
            }

            if (version) {
                rows.push({
                    title: 'Версия',
                    data: version,
                    router: this.versionRouter,
                    deletedText: 'Версия удалена',
                });
            }

            if (page) {
                rows.push({
                    title: 'Страница',
                    data: page,
                    router: this.pageRouter,
                    deletedText: 'Страница удалена',
                });
            }

            return rows;
        },
        solutionRouter() {
            const { development_center: developmentCenter, solution } = this.aiSession.location;

            return {
                name: 'AdminProjectVersionsMainView',
                params: {
                    developmentCenterId: developmentCenter.id,
                    solutionId: solution.id,
                },
            };
        },
        versionRouter() {
            const { development_center: developmentCenter, solution, version } = this.aiSession.location;

            return {
                name: 'VersionReviewMainView',
                params: {
                    developmentCenterId: developmentCenter.id,
                    solutionId: solution.id,
                    versionId: version.id,
                },
            };
        },
        pageRouter() {
            const { development_center: developmentCenter, solution, version, page } = this.aiSession.location;

            return {
                name: 'AdminLongreadUpdateView',
                params: {
                    developmentCenterId: developmentCenter.id,
                    solutionId: solution.id,
                    versionId: version.id,
                    longreadId: page.id,
                },
            };
        },
        blockRouter() {
            const { development_center: developmentCenter, solution, version, page, activity } = this.aiSession.location;

            return {
                name: 'AdminLongreadUpdateView',
                hash: `#activity-${activity.id}`,
                params: {
                    developmentCenterId: developmentCenter.id,
                    solutionId: solution.id,
                    versionId: version.id,
                    longreadId: page.id,
                },
            };
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.AiModalLocationPopup {
    max-width: 400px;
    padding: 20px 25px;
}

.AiModalLocationPopup__Content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.AiModalLocationPopup__Header {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
}

.AiModalLocationPopup__Title {
    @include typo-primary-medium;
}

.AiModalLocationPopup__BlockLink {
    display: flex;
    gap: 5px;
    align-items: center;
}

.AiModalLocationPopup__Layout {
    display: grid;
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    grid-template-columns: max-content 1fr;
}

.AiModalLocationPopup__LayoutTitle {
    @include typo-secondary-book;

    color: $gray-deep;
}

.AiModalLocationPopup__LayoutLink .sbs-link__text {
    max-width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.AiModalLocationPopup__LayoutLink.disabled,
.AiModalLocationPopup__BlockLink.disabled {
    opacity: 0.5;
}
</style>
