<template>
    <div
        v-if="itemData"
        class="AiHistoryModalItem">
        <SbsButton
            class="AiHistoryModalItem__IconWrapper"
            backgroundTheme="gray-ultra-light"
            :bordered="false"
            static
            :withNotify="isNotViewed">
            <InlineSvg
                class="AiHistoryModalItem__Icon"
                :src="itemData.icon" />
        </SbsButton>
        <div class="AiHistoryModalItem__Content">
            <div class="AiHistoryModalItem__ContentTitle">
                <span class="AiHistoryModalItem__ContentTitle--Entity">{{ itemData.text }}</span>
                {{ projectName }} в
                <span class="AiHistoryModalItem__ContentTitle--Entity">версии</span>
                {{ versionName }}
            </div>
            <div
                v-if="isProcessing"
                class="AiHistoryModalItem__ContentProcessed">
                <DotsLoader />
                Идет генерация
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { MAX_ENTITY_NAME_LENGTH } from '@/constants/AI.js';
import { isMessageProcessing } from '@/admin/components/AI/AiModal/helpers.js';
import { HISTORY_ITEM_DATA_MAP } from '@/admin/components/AI/constants.js';
import DotsLoader from '@/components/DotsLoader.vue';

export default {
    name: 'AiHistoryModalItem',
    components: {
        InlineSvg,
        DotsLoader,
    },
    computed: {
        isNotViewed() {
            return !(this.item.last_message?.is_viewed ?? true);
        },
        itemData() {
            return HISTORY_ITEM_DATA_MAP[this.item.prompt_type] || {
                text: '',
                icon: require('@/components/WysiwygRedactor/assets/icons/icon-question-bubble.svg'),
            };
        },
        projectName() {
            return this.getEntityClampName(this.item.location?.solution?.name ?? 'Проект');
        },
        versionName() {
            return this.getEntityClampName(this.item.location?.version?.name ?? 'Версия');
        },
        isProcessing() {
            return isMessageProcessing(this.item.last_message);
        },
    },
    methods: {
        getEntityClampName(name) {
            if (name.length <= MAX_ENTITY_NAME_LENGTH) return name;

            return `${name.slice(0, MAX_ENTITY_NAME_LENGTH)}...`;
        },
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss">
.AiHistoryModalItem {
    display: flex;
    gap: 10px;
    padding: 10px 0;
    cursor: pointer;

    &:hover {
        .AiHistoryModalItem__ContentTitle,
        .AiHistoryModalItem__ContentTitle--Entity {
            color: $primary;
        }
    }
}

.AiHistoryModalItem__IconWrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
}

.AiHistoryModalItem__Icon {
    width: 20px;
    height: 20px;

    path {
        fill: $gray-deep;
    }
}

.AiHistoryModalItem__Content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.AiHistoryModalItem__ContentTitle {
    @include typo-additional-book;

    &--Entity {
        color: $gray-deep;
    }
}

.AiHistoryModalItem__ContentProcessed {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    align-self: flex-start;
    height: 18px;
    padding: 0 5px;
    color: $primary;
    background: $lightblue;
    border-radius: 5px;

    @include typo-subsecondary-demi;
}
</style>
