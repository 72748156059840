<template>
    <AiQuestionBase
        class="AiQuestionMulti"
        :question="question">
        <div class="AiQuestionMulti__List">
            <div
                v-for="(answer, ind) in answers"
                :key="ind"
                class="AiQuestionMulti__Answer">
                <div class="AiQuestionMulti__ControlWrap">
                    <SbsCheckbox
                        class="AiQuestionMulti__AnswerControl"
                        disabled
                        :modelValue="answer.is_correct" />
                </div>
                <TextBlock
                    class="AiQuestionMulti__AnswerTitle"
                    kind="Desktop/font/body/s1-book">
                    {{ answer.title }}
                </TextBlock>
            </div>
        </div>
    </AiQuestionBase>
</template>

<script>
import AiQuestionBase from '@/admin/components/AI/AiModal/AiQuestionBase.vue';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiQuestionMulti',
    components: {
        TextBlock,
        AiQuestionBase,
    },
    computed: {
        answers() { return this.question?.answers ?? []; },
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.AiQuestionMulti__List {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.AiQuestionMulti__Answer {
    display: flex;
    gap: 10px;
}

.AiQuestionMulti__ControlWrap {
    flex-shrink: 0;
    padding: 3px 0;
}

.AiQuestionMulti__AnswerControl {
    .sbs-checkbox__item {
        padding: 0;
    }

    .sbs-checkbox__control-wrapper {
        margin: 0;
    }

    &.sbs-checkbox.disabled.checked .sbs-checkbox__control {
        background: rgba(#c4c6cd, 0.4);
        border-color: transparent;
    }
}

.AiQuestionMulti__AnswerTitle {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
}
</style>
