<template>
    <div class="AiQuestionBase">
        <AiQuestionLabel
            class="AiQuestionBase__Label"
            :type="question.type" />
        <TextBlock
            class="AiQuestionBase__Title"
            kind="Desktop/font/body/m2-medium">
            <slot name="title">
                {{ question.title }}
            </slot>
        </TextBlock>
        <TextBlock
            class="AiQuestionBase__Description"
            kind="Desktop/font/body/s1-book">
            <slot name="title">
                {{ question.description }}
            </slot>
        </TextBlock>
        <div class="AiQuestionBase__Answers">
            <slot></slot>
        </div>
        <div class="AiQuestionBase__Actions">
            <SbsTooltip
                position="bottom-end"
                content="Копировать">
                <SbsButtonIcon
                    class="AiQuestionBase__CopyBtn"
                    borderTheme="white"
                    backgroundTheme="white"
                    hoverBackground="white"
                    hoverBorder="primary"
                    bordered
                    hoverShadow="gray"
                    :expandClickArea="false"
                    @click="handleClickCopy">
                    <InlineSvg
                        class="AiModalActionBtns__Icon"
                        :src="require('@/assets/svg/icons8-copy.inline.svg')" />
                </SbsButtonIcon>
            </SbsTooltip>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { useClipboardItems } from '@vueuse/core';
import { mapMutations } from 'vuex';
import useSnackbar from '@/hooks/snackbars.js';
import { getCopyItemsForQuestions } from '@/admin/components/AI/AiModal/helpers.js';
import AiQuestionLabel from '@/admin/components/AI/AiModal/AiQuestionLabel.vue';
import TextBlock from '@/components/UI/TextBlock.vue';

export default {
    name: 'AiQuestionBase',
    components: {
        InlineSvg,
        TextBlock,
        AiQuestionLabel,
    },
    methods: {
        ...mapMutations('AI', ['setCopyQuestions']),
        async handleClickCopy() {
            try {
                const copyItems = getCopyItemsForQuestions([this.question]);

                await this.clipboard.copy([copyItems]);

                this.setCopyQuestions([this.question]);

                this.snackbar.createSnackbar({
                    type: 'success',
                    message: 'Скопировано в буфер обмена',
                });
            } catch (e) {
                console.error({ e });

                if (e.message && /permission/.test(e.message)) {
                    this.snackbar.createSnackbar({
                        type: 'error',
                        message: 'Нет разрешения копировать в буфер обмена. Разрешите копировать в буфер обмена в настройках вкладки',
                    });
                } else {
                    this.snackbar.createSnackbar({
                        type: 'error',
                        message: 'Не удалось скопировать. Попробуй еще раз.',
                    });
                }
            }
        },
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const clipboard = useClipboardItems();
        const snackbar = useSnackbar();

        return {
            clipboard,
            snackbar,
        };
    },
};
</script>

<style lang="scss">
.AiQuestionBase {
    position: relative;
    padding: 15px;
    background: #f4f5f8;
    border-radius: 15px;
}

.AiQuestionBase__Description {
    margin-top: 5px;
}

.AiQuestionBase__Label {
    margin-bottom: 10px;
}

.AiQuestionBase__Answers {
    margin-top: 15px;
}

.AiQuestionBase__Actions {
    position: absolute;
    top: 15px;
    right: 15px;
}

.AiQuestionBase__CopyBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
}
</style>
