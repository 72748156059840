<template>
    <component
        :is="messageComponent"
        v-if="messageComponent"
        :message="message" />
</template>

<script>
import { AiMessageAuthorType } from '@/admin/components/AI/AiModal/contants.js';
import AiMessageAssistant from '@/admin/components/AI/AiModal/AiMessageAssistant.vue';
import AiMessageUser from '@/admin/components/AI/AiModal/AiMessageUser.vue';

export default {
    name: 'AiMessage',
    computed: {
        messageComponent() {
            if (this.message.author === AiMessageAuthorType.ASSISTANT) {
                return AiMessageAssistant;
            }

            if (this.message.author === AiMessageAuthorType.USER) {
                return AiMessageUser;
            }

            return null;
        },
    },
    props: {
        /**
         * @type {import('vue').PropType<{
         *       id: number,
         *       prompt_type: 'improve_writing' | 'correct_writing' | 'make_shorter' | 'make_longer' | 'summarize' | 'generate_image' | 'generate_incorrect_answers' | 'make_question' | 'generate_consolidate_material_task' | 'generate_testing_skills_task' | 'generate_reflection_on_material_task' | 'generate_test',
         *       author: 'user' | 'assistant' | 'system',
         *       status: 'created' | 'processing' | 'processed' | 'canceled' | 'failed',
         *       updated_at: string,
         *       is_viewed: boolean,
         *       [key: string]: any,
         *     }>}
         */
        message: {
            type: Object,
            required: true,
        },
    },
};
</script>
