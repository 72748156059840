<template>
    <SbsCombobox
        v-bind="dropdownData"
        v-model:opened="isShowDropdown"
        :modelValue="comboboxModel"
        :items="items"
        :label="label"
        @update:modelValue="updateComboboxValue">
        <template #list="{ items: selectItems }">
            <SbsSelectListItem
                v-for="selectItem in selectItems"
                :key="selectItem.value"
                class="AiSupportModal__ComboboxItem"
                labelClass="AiSupportModal__ComboboxLabel"
                :label="selectItem.label"
                :modelValue="selectItem.value"
                hoverBackground="lightblue"
                hideControl>
                <template #default="{ selected }">
                    <div class="AiSupportModal__ComboboxItemSelected">
                        <div>{{ selectItem.label }}</div>
                        <InlineSvg
                            v-if="selected"
                            :src="require('@/assets/svg/icon-accept.svg')" />
                    </div>
                </template>
            </SbsSelectListItem>
        </template>
    </SbsCombobox>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

const defaultComboboxData = {
    class: 'AiSupportCombobox',
    colorTheme: 'primary',
    popupProps: {
        offset: 15,
    },
    fullWidth: true,
    backgroundTheme: 'white',
};

export default {
    name: 'AiSupportCombobox',
    components: { InlineSvg },
    emits: ['update:modelValue'],
    data() {
        return {
            isShowDropdown: false,
        };
    },
    computed: {
        comboboxModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        dropdownData() {
            return {
                ...defaultComboboxData,
                ...this.comboboxData,
            };
        },
    },
    methods: {
        updateComboboxValue(arrayValue) {
            this.isShowDropdown = false;
            this.comboboxModel = arrayValue;
        },
    },
    props: {
        modelValue: {
            type: Array,
            default: () => ([]),
        },
        items: {
            type: Array,
            default: () => ([]),
        },
        comboboxData: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.AiSupportModal__ComboboxItem {
    margin-bottom: 2px;

    &:last-child {
        margin-bottom: 5px;
    }
}

.AiSupportModal__ComboboxLabel {
    border-radius: 0;
    height: 34px;
    padding: 7px 10px;
}

.AiSupportModal__ComboboxItemSelected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    @include typo-additional-book;
}
</style>
