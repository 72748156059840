<template>
    <div
        v-if="isOpen"
        class="info-card"
        :class="wrapperClasses">
        <InfoIcon
            class="info-card__icon mr-2"
            :class="[`align-self-${alignIcon}`]"
            iconColor="white"
            :backgroundTheme="iconBackground"
            tooltipDisabled />
        <TextBlock
            class="info-card__text mr-auto"
            :kind="TextBlockKinds['Desktop/Подпись №2 (Book)']">
            {{ text }}
        </TextBlock>
        <SbsButtonIcon
            v-if="hasClose"
            class="info-card__close-btn align-self-start"
            iconName="xmark"
            backgroundTheme="transparent"
            colorTheme="gray"
            iconSize="medium"
            :bordered="false"
            @click="close" />
    </div>
</template>

<script>
import { AllColors } from '@sbs/uikit-constructor/src/lib/utils/colors.js';
import InfoIcon from '@/components/UI/InfoIcon.vue';
import TextBlock, { TextBlockKinds } from '@/components/UI/TextBlock.vue';

export default {
    name: 'InfoCard',
    components: {
        TextBlock,
        InfoIcon,
    },
    emits: ['close'],
    data: () => ({
        isOpen: true,
        TextBlockKinds,
    }),
    computed: {
        wrapperClasses() {
            return [
                `bg-${this.backgroundTheme}`,
                { 'w-100': this.fullWidth },
            ];
        },
    },
    methods: {
        close() {
            this.isOpen = false;
            this.$emit('close');
        },
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        backgroundTheme: {
            type: String,
            default: AllColors.GRADIENT_PALE_YELLOW,
            validator: value => Object.values(AllColors).includes(value),
        },
        hasClose: {
            type: Boolean,
            default: true,
        },
        iconBackground: {
            type: String,
            default: AllColors.GRADIENT_YELLOW,
            validator: value => Object.values(AllColors).includes(value),
        },
        alignIcon: {
            type: String,
            default: 'start',
        },
    },
};
</script>

<style lang="scss">
.info-card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 15px;

    &.bg-gradient-pale-yellow {
        background: #fff2e5 !important; /* stylelint-disable-line declaration-no-important */
    }
}

.info-card__icon {
    .info-icon {
        width: 20px;
        height: 20px;

        &.bg-gradient-yellow {
            background: #f2a174 !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

.info-card__close-btn {
    margin-left: 5px;
}
</style>
