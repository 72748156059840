<template>
    <div class="AiModalResultStub">
        <div class="AiModalResultStub__IconWrapper">
            <InlineSvg :src="require('@/assets/svg/icons8-bubble-stars-education-cap.svg')" />
        </div>
        <div class="AiModalResultStub__Description">
            Скажите нейросети, что нужно<br>сделать и чем больше будет деталей<br> описано, тем лучше результат
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiModalResultStub',
    components: {
        InlineSvg,
    },
};
</script>

<style lang="scss" scoped>
.AiModalResultStub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-top: 80px;
}

.AiModalResultStub__IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background: $gray-ultra-light;
  border-radius: 50%;
}

.AiModalResultStub__Description {
  color: $gray-deep;
  text-align: center;
}
</style>
