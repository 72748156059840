<template>
    <div class="AiModalSpinner">
        <SbsSpinner
            size="large"
            colorTheme="blue" />
    </div>
</template>

<script>
export default {
    name: 'AiModalSpinner',
};
</script>

<style lang="scss" scoped>
.AiModalSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
