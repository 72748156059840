<template>
    <SbsButton
        class="AiHistoryPopupBtn"
        backgroundTheme="white"
        borderTheme="transparent"
        static
        :withNotify="withNotify">
        <InlineSvg
            class="AiHistoryPopupBtn__Icon"
            :src="require('@/assets/svg/icon-ai-start.svg')" />
    </SbsButton>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiHistoryPopupBtn',
    components: {
        InlineSvg,
    },
    props: {
        withNotify: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.AiHistoryPopupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    padding: 0;
    border-radius: 50%;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.12);

    .sbs-button__notify {
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        border-color: #ebedf1;
    }
}

.AiHistoryPopupBtn__Icon {
    flex-shrink: 0;
}
</style>
