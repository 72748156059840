<template>
    <SbsButtonIcon
        class="AiHistoryPopupCloseBtn"
        bordered
        borderTheme="gray-light">
        <InlineSvg
            class="AiHistoryPopupCloseBtn__Icon"
            :src="require('@/assets/svg/icon-close-alt.svg')" />
    </SbsButtonIcon>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
    name: 'AiHistoryPopupCloseBtn',
    components: {
        InlineSvg,
    },
};
</script>

<style lang="scss" scoped>
.AiHistoryPopupCloseBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.AiHistoryPopupCloseBtn__Icon {
    width: 16px;
    height: 16px;
}
</style>
